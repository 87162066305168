import reseller from 'src/reseller';
import {createResources} from 'src/utils/i18n';

const namespaces = require('./namespaces');

const supportedLangs = reseller.supportedLanguages;
const resources = createResources(supportedLangs, namespaces.all);

export default {
  ns: namespaces.all,
  defaultNS: namespaces.common,
  fallbackNS: namespaces.common,
  fallbackLng: 'en',
  supportedLngs: supportedLangs,
  nonExplicitSupportedLngs: true,
  interpolation: {
    prefix: '{',
    suffix: '}',
  },
  saveMissing: false,
  // console logging ON
  debug: true,
  resources,
  react: {
    wait: true,
    bindI18n: 'languageChanged loaded',
    bindStore: 'added removed',
    nsMode: 'default',
  },
};

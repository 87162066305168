import i18n from 'i18next';

export const getLangValue = lang => (lang ? lang.substr(0, 2) : 'en');

export const getLabel = (t, labelKey, label) =>
  labelKey ? t(labelKey) : label;

export const createResources = (languages, namespacesList) => {
  const resources = {};

  languages.forEach(lng => {
    const nsResources = {};
    namespacesList.forEach(ns => {
      // languages param - its value is unknown at compile time.
      // So, all trans of all of the languages are included into the bundle.
      // To avoid it, need to specify the list of supported languages via env vars.
      // TODO do not include all trans of all of the languages into the bundle
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const commonTrans = require(`src/i18n/commonTrans/${lng}/${ns}.json`);

      let resellerSpecificTrans;
      try {
        // eslint-disable-next-line global-require,import/no-dynamic-require,no-undef
        resellerSpecificTrans = require(`src/i18n/resellerSpecificTrans/${__reseller__}/${lng}/${ns}.json`);
      } catch (err) {
        // Do nothing if file doesn't exist
      }

      nsResources[ns] = resellerSpecificTrans
        ? {
            ...commonTrans,
            ...resellerSpecificTrans,
          }
        : commonTrans;
    });

    resources[lng] = nsResources;
  });

  return resources;
};

export const numberToLocaleString = value =>
  value.toLocaleString(getLangValue(i18n.language));

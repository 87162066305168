import React, {Suspense, Fragment} from 'react';
import {Switch, Route} from 'react-router-dom';

import LoadingScreen from 'src/components/LoadingScreen';

const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            /* eslint-disable-next-line react/no-array-index-key */
            key={`${i}_${route.path}`}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

export default renderRoutes;

export default function decode(frontendData) {
  const {
    clientType,
    downloadLink,
    currentVersion,
    minimalVersion,
  } = frontendData;

  return {
    type: clientType,
    download_link: downloadLink,
    current_version: currentVersion,
    minimal_version: minimalVersion,
  };
}

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import i18next from 'i18next';

import ErrorPage from 'src/views/errors/FrontendError';
import AuthContext from './contexts/SimpleTokenAuthContext';

// access global vars from build-constants.js
/* eslint-disable no-undef */
const isProduction = __prod__;
const dsn = `${__sentryDsn__}`;
// const latestCommit = `${__latestCommitInfo__}`;
/* eslint-enable no-undef */

export default class ErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  static contextType = AuthContext;

  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error,
      errorInfo,
    });

    if (isProduction && dsn) {
      // Log error to error reporting service here
      Sentry.withScope(scope => {
        const username = this.context?.user?.name;

        scope.setUser({key: username, id: username, username});

        scope.setExtra('errorInfo', errorInfo);
        // scope.setExtra('latestCommit', latestCommit);
        scope.setExtra('lang', i18next.language);

        const eventId = Sentry.captureException(error);
        /* eslint-disable-next-line no-console */
        console.log(`successfully sent error to Sentry, eventId = ${eventId}`);
      });
    }
  }

  render() {
    const {error, errorInfo} = this.state;

    if (errorInfo || error) {
      return <ErrorPage />;
    }

    // Normally, just render children
    return this.props.children;
  }
}

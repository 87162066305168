import React, {memo} from 'react';

// IMAGES is defined during the build process.
/* eslint-disable import/no-unresolved */
import logoImg from 'IMAGES/logo.png';
import logoDarkImg from 'IMAGES/logoDark.png';
/* eslint-enable import/no-unresolved */
import useSettings from 'src/hooks/useSettings';
import {THEMES} from 'src/constants';

const Logo = props => {
  const {settings} = useSettings();

  return (
    <img
      src={settings.theme === THEMES.ONE_DARK ? logoDarkImg : logoImg}
      alt="logo"
      {...props}
    />
  );
};

export default memo(Logo);

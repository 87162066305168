// IMPORTANT
// This code must correspond to:
// * prop type
export const decode = origData => {
  const {
    userid: userId,
    replied,
    logs_path: origLogsUrl,
    ...restProps
  } = origData;
  const logsUrl = origLogsUrl ? `${origLogsUrl}.zip` : '';

  return {userId, replyDate: replied || undefined, logsUrl, ...restProps};
};

export const normalizeOrderBy = orderBy => {
  if (!orderBy) {
    return orderBy;
  }

  if (orderBy === 'userId') {
    return 'userid';
  }

  if (orderBy === 'replyDate') {
    return 'replied';
  }

  return orderBy;
};

export const decode = origData => {
  const {
    content_deleted: contentDeleted,
    deleted,
    is_active: isActive,
    last_activity: lastActivity,
    last_login: lastLogin,
    quota_size: quotaSize,
    storage_size: storageSize,
    tenant_id: tenantId,
    tenant_name: tenantName,
    updated,
    ...restProps
  } = origData;

  return {
    contentDeleted: contentDeleted || null,
    deleted: deleted || null,
    isActive,
    lastActivity: lastActivity || null,
    lastLogin: lastLogin || null,
    quotaSize,
    storageSize,
    tenantId,
    tenantName,
    updated: updated || null,
    ...restProps,
  };
};

export const normalizeOrderBy = orderBy => {
  if (!orderBy) {
    return orderBy;
  }

  const uiName2BackendName = {
    id: 'userid',
    storageSize: 'storage_size',
    quotaSize: 'quota_size',
    deleted: 'deleted_date',
  };

  return uiName2BackendName[orderBy] || orderBy;
};

// IMPORTANT
// This code must correspond to:
// * ssoClient prop type
// * encode method
export default function decode(origSsoClient) {
  const {
    client_id: id,
    sso_frontend_url: frontendUrl,
    callback_url: callbackUrl,
    default: isDefault,
    ...restProps
  } = origSsoClient;

  return {id, frontendUrl, callbackUrl, isDefault, ...restProps};
}

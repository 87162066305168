import React from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {SnackbarProvider} from 'notistack';
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
  makeStyles,
  Button,
} from '@material-ui/core';
import {ConfirmProvider} from 'material-ui-confirm';
import {I18nextProvider} from 'react-i18next';
import ModalContainer from 'react-modal-promise';
import {
  QueryCache,
  ReactQueryCacheProvider,
  ReactQueryConfigProvider,
} from 'react-query';
import {ReactQueryDevtools} from 'react-query-devtools';

import DateTimePickersProvider from 'src/components/DateTimePickers/DateTimePickersProvider';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import {AuthProvider} from 'src/contexts/SimpleTokenAuthContext';
import useSettings from 'src/hooks/useSettings';
import {createTheme} from 'src/theme';
import Routes from 'src/routes';
import Text from 'src/components/DisplayStrings/Text';
import {routerBasename as publicPath} from 'src/utils/router';
import i18n from './i18n';
import ErrorBoundary from './ErrorBoundary';

const package_json = require('../package.json');

const jss = create({plugins: [...jssPreset().plugins, rtl()]});
const history = createBrowserHistory({basename: publicPath});

const useStyles = makeStyles(() => ({
  confirmTitle: {
    fontSize: 'large',
  },
}));

const queryCache = new QueryCache();
const queryConfigOverrides = {
  queries: {
    retry: false,
  },
};

const App = () => {
  const {settings} = useSettings();
  const classes = useStyles();
  // eslint-disable-next-line global-require

  console.log(
    'Admin version',
    `${package_json.major_version}.${package_json.version}`,
  );

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <SnackbarProvider
          dense
          preventDuplicate
          autoHideDuration={5000}
          maxSnack={4}
          ref={notistackRef}
          action={key => <Button onClick={onClickDismiss(key)}>Dismiss</Button>}
        >
          <I18nextProvider i18n={i18n}>
            <DateTimePickersProvider>
              <ConfirmProvider
                defaultOptions={{
                  confirmationButtonProps: {
                    style: {
                      color: 'red',
                      marginRight: '16px',
                      marginBottom: '16px',
                    },
                    variant: 'outlined',
                    color: 'default',
                  },
                  cancellationButtonProps: {
                    variant: 'outlined',
                    style: {
                      marginRight: '16px',
                      marginBottom: '16px',
                    },
                  },
                  title: (
                    <span className={classes.confirmTitle}>
                      <Text transKey="confirm__defaultTitle__text" />
                    </span>
                  ),
                  cancellationText: (
                    <span>
                      <Text transKey="buttonText__cancel" />
                    </span>
                  ),
                }}
              >
                <ReactQueryCacheProvider queryCache={queryCache}>
                  <ReactQueryConfigProvider config={queryConfigOverrides}>
                    <Router history={history} basename={publicPath}>
                      <AuthProvider>
                        <ErrorBoundary>
                          <GlobalStyles />
                          <ScrollReset />
                          <Routes />
                          <ModalContainer />
                        </ErrorBoundary>
                      </AuthProvider>
                    </Router>
                    <ReactQueryDevtools initialIsOpen={false} />
                  </ReactQueryConfigProvider>
                </ReactQueryCacheProvider>
              </ConfirmProvider>
            </DateTimePickersProvider>
          </I18nextProvider>
        </SnackbarProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;

import merge from 'lodash/merge';
import {colors, createMuiTheme, responsiveFontSizes} from '@material-ui/core';

import {THEMES} from 'src/constants';
import reseller from 'src/reseller';
import {softShadows, strongShadows} from './shadows';
import typography from './typography';

const COLOR__BRAND_NORMAL = reseller.colors.brandNormal;
const COLOR__BRAND_DARK = reseller.colors.brandDark;
const COLOR__ACCENT = reseller.colors.accent;
const colorAccent = COLOR__ACCENT || COLOR__BRAND_NORMAL;

const darkThemeSettings = reseller.colors.darkTheme;
const darkThemeBrandNormalColor =
  darkThemeSettings?.brandNormal || COLOR__BRAND_NORMAL;
const darkThemeBrandDarkColor =
  darkThemeSettings?.brandDark || COLOR__BRAND_DARK;
const darkThemeAccentColor =
  darkThemeSettings?.accent || darkThemeBrandNormalColor;

const inputFocusedColor = '#000';
const inputFocusedColorForDarkTheme = '#fff';

// For some resellers red color is specified as a brand color.
// That brand color is also used as border color for focused inputs.
// But red color is also used for v8n error message => focused color vs v8n color.
// In such situations there is a possibility to specify custom focused color for
// both: light and dark themes.
const getFormInputFocusOverrides = borderColor => {
  if (!borderColor) {
    return undefined;
  }

  return {
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor,
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: borderColor,
        },
      },
    },
  };
};

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    // This affects only table's header
    MuiTableCell: {
      root: {
        padding: 16,
      },
    },
    MuiCard: {
      root: {
        overflow: 'auto',
      },
    },
  },
  props: {
    MuiInput: {
      color: inputFocusedColor ? undefined : 'secondary',
    },
    MuiTextField: {
      color: inputFocusedColor ? undefined : 'secondary',
      variant: 'outlined',
      size: 'small',
    },
    MuiLink: {
      color: 'secondary',
    },
  },
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600],
          },
        },
      },
      MuiSnackbarContent: {
        root: {
          '&[class*="root"]': {
            backgroundColor: '#252A31',
            fontWeight: 'bold',
          },
          '&[class*="variantSuccess"]': {
            backgroundColor: '#252A31',
            fontWeight: 'bold',
          },
          '&[class*="variantError"]': {
            backgroundColor: '#252A31',
            fontWeight: 'bold',
          },
          '&[class*="variantInfo"]': {
            backgroundColor: '#252A31',
            fontWeight: 'bold',
          },
          '&[class*="variantWarning"]': {
            backgroundColor: '#252A31',
            fontWeight: 'bold',
          },
        },
      },
      ...getFormInputFocusOverrides(inputFocusedColor),
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600],
      },
      background: {
        default: colors.common.white,
        dark: '#f4f6f8',
        paper: colors.common.white,
      },
      primary: {
        main: COLOR__BRAND_NORMAL,
        dark: COLOR__BRAND_DARK,
        contrastText: '#fff',
      },
      secondary: {
        main: colorAccent,
        contrastText: '#fff',
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[600],
      },
    },
    shadows: softShadows,
  },
  {
    name: THEMES.ONE_DARK,
    overrides: {
      MuiSnackbarContent: {
        root: {
          '&[class*="root"]': {
            backgroundColor: '#e0e0e0',
            color: '#111',
            fontWeight: 'bold',
          },
          '&[class*="variantSuccess"]': {
            backgroundColor: '#e0e0e0',
            color: '#111',
            fontWeight: 'bold',
          },
          '&[class*="variantError"]': {
            backgroundColor: '#e0e0e0',
            color: '#111',
            fontWeight: 'bold',
          },
          '&[class*="variantInfo"]': {
            backgroundColor: '#e0e0e0',
            color: '#111',
            fontWeight: '#111',
          },
          '&[class*="variantWarning"]': {
            backgroundColor: '#e0e0e0',
            color: '#111',
            fontWeight: 'bold',
          },
        },
      },
      ...getFormInputFocusOverrides(inputFocusedColorForDarkTheme),
    },
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34',
      },
      primary: {
        main: darkThemeBrandNormalColor,
        dark: darkThemeBrandDarkColor,
        contrastText: '#fff',
      },
      secondary: {
        main: darkThemeAccentColor,
        contrastText: '#000',
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb',
      },
    },
    shadows: strongShadows,
  },
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find(theme => theme.name === config.theme);

  if (!themeOptions) {
    // eslint-disable-next-line no-console
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    merge({}, baseOptions, themeOptions, {direction: config.direction}),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

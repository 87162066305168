import React, {lazy} from 'react';
import {Redirect} from 'react-router-dom';

import {
  ROUTE__BUCKET__CREATE,
  ROUTE__BUCKET__EDIT,
  ROUTE__BUCKETS__TABLE,
  ROUTE__CLIENT_VERSION__CREATE,
  ROUTE__CLIENT_VERSION__EDIT,
  ROUTE__CLIENT_VERSIONS__TABLE,
  ROUTE__DASHBOARD,
  ROUTE__DASHBOARD__MANAGEMENT,
  ROUTE__ERROR__404,
  ROUTE__ERROR__403,
  ROUTE__FEEDBACK__CARD,
  ROUTE__FEEDBACKS__LIST,
  ROUTE__KEY_VALUE__CREATE,
  ROUTE__KEY_VALUE__EDIT,
  ROUTE__KEY_VALUE__LIST,
  ROUTE__LOGIN,
  ROUTE__PACKAGE_CARD,
  ROUTE__PACKAGES_TABLE,
  ROUTE__MESSAGE_DISTRIBUTION,
  ROUTE__MESSAGES__CREATE,
  ROUTE__MESSAGES__VIEW,
  ROUTE__METRICS,
  ROUTE__NOTIF_TEMPLATE__CREATE,
  ROUTE__NOTIF_TEMPLATE__EDIT,
  ROUTE__NOTIF_TEMPLATES__TABLE,
  ROUTE__OFFER__CREATE,
  ROUTE__OFFER__EDIT,
  ROUTE__OFFERS__TABLE,
  ROUTE__PROMOCODE__CARD,
  ROUTE__PROMOCODE__CREATE,
  ROUTE__PROMOCODES_TABLE,
  ROUTE__PUSH_TOPIC_TASK,
  ROUTE__SSO_CLIENT__CREATE,
  ROUTE__SSO_CLIENT__EDIT,
  ROUTE__SSO_CLIENT__LIST,
  ROUTE__STORAGE_ANALYTICS,
  ROUTE__TENANT__CREATE,
  ROUTE__TENANT__EDIT,
  ROUTE__TENANTS__TABLE,
  ROUTE__USER,
  ROUTE__USER__CREATE,
  ROUTE__USER__EDIT,
  ROUTE__USER__PHOTO_OPERATION,
  ROUTE__USER__PUSH_SUBSCRIPTION,
  ROUTE__USER_MESSAGE,
  ROUTE__USERS,
  ROUTE__OFFER__RAW_VIEW,
  ROUTE__USERS_CSV__TABLE,
  ROUTE__TEST_SNACK,
  ROUTE__PROMOCODE__GROUP,
  ROUTE__REPORTS,
  ROUTE__REPORTS_CREATE,
  ROUTE__USERS_CSV__TABLE_CREATE,
  ROUTE__USERS__ADMINS_TABLE,
  ROUTE__USERS__ADMINS_EDIT,
} from 'src/constants/routes';
import AuthGuard from 'src/components/RouteGuards/AuthGuard';
import GuestGuard from 'src/components/RouteGuards/GuestGuard';
import AdminGuard from 'src/components/RouteGuards/AdminGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import AdminOrReadOnlyAdminGuard from 'src/components/RouteGuards/AdminOrReadOnlyAdminGuard';

// Defined during the building from the environment.
// eslint-disable-next-line no-undef
const PROVISIONER_ADDR = `${__provisionerUrl__}`;
const isProvisionerSpecified = !!PROVISIONER_ADDR;

// Defined during the building from the environment.
// eslint-disable-next-line no-undef,global-require,import/no-dynamic-require
const resellerName = `${__reseller__}`;
const isMegadisk = resellerName === 'megadisk';
const isVodafoneTurkey = resellerName === 'vodafoneTurkey';
const isCloudmax = resellerName === 'cloudmax';

const createRoutesConfig = () => {
  const managementSectionDefaultRoute = ROUTE__USERS;

  return [
    {
      exact: true,
      path: ROUTE__ERROR__404,
      component: lazy(() =>
        import(/* webpackChunkName: "common" */ 'src/views/errors/NotFound'),
      ),
    },
    {
      exact: true,
      path: ROUTE__ERROR__403,
      component: lazy(() =>
        import(/* webpackChunkName: "common" */ 'src/views/errors/Forbidden'),
      ),
    },
    {
      exact: true,
      guard: GuestGuard,
      path: ROUTE__LOGIN,
      component: lazy(() =>
        import(/* webpackChunkName: "common" */ 'src/views/auth/LoginView'),
      ),
    },
    {
      path: ROUTE__DASHBOARD,
      guard: AuthGuard,
      layout: DashboardLayout,
      routes: [
        //-------------------------------------------------------------
        // Key-Value
        {
          exact: true,
          path: ROUTE__KEY_VALUE__LIST,
          component: lazy(() =>
            import(
              /* webpackChunkName: "key_value" */ 'src/views/key-value/List'
            ),
          ),
          guard: AdminGuard,
        },
        {
          exact: true,
          path: ROUTE__TEST_SNACK,
          component: lazy(() =>
            import(/* webpackChunkName: "feedbacks" */ 'src/views/toast-test'),
          ),
        },
        {
          exact: true,
          path: [ROUTE__KEY_VALUE__CREATE, ROUTE__KEY_VALUE__EDIT],
          component: lazy(() =>
            import(
              /* webpackChunkName: "key_value" */ 'src/views/key-value/CreateEdit'
            ),
          ),
          guard: AdminGuard,
        },
        // Key-Value
        //-------------------------------------------------------------
        // SSO Clients
        {
          exact: true,
          path: ROUTE__SSO_CLIENT__LIST,
          component: lazy(() =>
            import(
              /* webpackChunkName: "sso_clients" */ 'src/views/sso-clients/List'
            ),
          ),
          guard: AdminGuard,
        },
        {
          exact: true,
          path: [ROUTE__SSO_CLIENT__CREATE, ROUTE__SSO_CLIENT__EDIT],
          component: lazy(() =>
            import(
              /* webpackChunkName: "sso_clients" */ 'src/views/sso-clients/CreateEdit'
            ),
          ),
          guard: AdminGuard,
        },
        // SSO Clients
        //-------------------------------------------------------------
        // Feedbacks
        {
          exact: true,
          path: ROUTE__FEEDBACKS__LIST,
          component: lazy(() =>
            import(
              /* webpackChunkName: "feedbacks" */ 'src/views/feedbacks/List'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__FEEDBACK__CARD,
          component: lazy(() =>
            import(
              /* webpackChunkName: "feedbacks" */ 'src/views/feedbacks/ItemDetails'
            ),
          ),
        },
        // Feedbacks
        //-------------------------------------------------------------
        // Users
        {
          exact: true,
          path: ROUTE__USERS,
          component: lazy(() =>
            import(/* webpackChunkName: "users" */ 'src/views/users/Table'),
          ),
        },
        {
          exact: true,
          path: ROUTE__USER__CREATE,
          component: lazy(() =>
            import(/* webpackChunkName: "users" */ 'src/views/users/Create'),
          ),
        },
        {
          exact: true,
          path: ROUTE__USER__EDIT,
          component: lazy(() =>
            import(/* webpackChunkName: "users" */ 'src/views/users/Edit'),
          ),
        },
        {
          exact: true,
          path: ROUTE__USER__PHOTO_OPERATION,
          component: lazy(() =>
            import(
              /* webpackChunkName: "users" */ 'src/views/users/Card/PhotoOperationsLog/Card'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__USER__PUSH_SUBSCRIPTION,
          component: lazy(() =>
            import(
              /* webpackChunkName: "users" */ 'src/views/users/Card/Messages/PushSubscriptions/Card'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__USER,
          component: lazy(() =>
            import(/* webpackChunkName: "users" */ 'src/views/users/Card'),
          ),
        },
        {
          exact: true,
          path: ROUTE__USERS_CSV__TABLE,
          component: lazy(() =>
            import(
              /* webpackChunkName: "users" */ 'src/views/users/ExportUsers'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__USERS_CSV__TABLE_CREATE,
          component: lazy(() =>
            import(
              /* webpackChunkName: "users" */ 'src/views/users/CreateReports'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__USERS__ADMINS_TABLE,
          component: lazy(() =>
            import(
              /* webpackChunkName: "users" */ 'src/views/users/TableAdmins'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__USERS__ADMINS_EDIT,
          component: lazy(() =>
            import(/* webpackChunkName: "users" */ 'src/views/users/EditAdmin'),
          ),
        },
        // Users
        //-------------------------------------------------------------
        // Client versions
        {
          exact: true,
          path: ROUTE__CLIENT_VERSIONS__TABLE,
          component: lazy(() =>
            import(
              /* webpackChunkName: "client_versions" */ 'src/views/client-versions/Table'
            ),
          ),
        },
        {
          exact: true,
          path: [ROUTE__CLIENT_VERSION__CREATE, ROUTE__CLIENT_VERSION__EDIT],
          component: lazy(() =>
            import(
              /* webpackChunkName: "client_versions" */ 'src/views/client-versions/CreateEdit'
            ),
          ),
        },
        // Client versions
        //-------------------------------------------------------------
        // Orders
        {
          exact: true,
          path: ROUTE__OFFERS__TABLE,
          component: lazy(() =>
            import(/* webpackChunkName: "orders" */ 'src/views/offers/Table'),
          ),
        },
        {
          exact: true,
          path: [ROUTE__OFFER__CREATE, ROUTE__OFFER__EDIT],
          component: lazy(() =>
            import(
              /* webpackChunkName: "orders" */ 'src/views/offers/CreateEdit'
            ),
          ),
        },
        {
          exact: true,
          path: [ROUTE__OFFER__RAW_VIEW],
          component: lazy(() =>
            import(/* webpackChunkName: "orders" */ 'src/views/offers/RawView'),
          ),
        },
        // Orders
        //-------------------------------------------------------------
        // Tenants
        {
          exact: true,
          path: ROUTE__TENANTS__TABLE,
          component: lazy(() =>
            import(/* webpackChunkName: "tenants" */ 'src/views/tenants/Table'),
          ),
          guard: AdminOrReadOnlyAdminGuard,
        },
        {
          exact: true,
          path: [ROUTE__TENANT__CREATE, ROUTE__TENANT__EDIT],
          component: lazy(() =>
            import(
              /* webpackChunkName: "tenants" */ 'src/views/tenants/CreateEdit'
            ),
          ),
          guard: AdminOrReadOnlyAdminGuard,
        },
        // Tenants
        //-------------------------------------------------------------
        // Buckets
        {
          exact: true,
          path: ROUTE__BUCKETS__TABLE,
          component: lazy(() =>
            import(/* webpackChunkName: "buckets" */ 'src/views/buckets/Table'),
          ),
          guard: AdminOrReadOnlyAdminGuard,
        },
        {
          exact: true,
          path: [ROUTE__BUCKET__CREATE, ROUTE__BUCKET__EDIT],
          component: lazy(() =>
            import(
              /* webpackChunkName: "buckets" */ 'src/views/buckets/CreateEdit'
            ),
          ),
          guard: AdminOrReadOnlyAdminGuard,
        },
        // Buckets
        //-------------------------------------------------------------
        // Messages section
        {
          exact: true,
          path: ROUTE__MESSAGES__CREATE,
          component: lazy(() =>
            import(
              /* webpackChunkName: "messages" */ 'src/views/messages/Create'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__MESSAGES__VIEW,
          component: lazy(() =>
            import(
              /* webpackChunkName: "messages" */ 'src/views/messages/View'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__USER_MESSAGE,
          component: lazy(() =>
            import(
              /* webpackChunkName: "messages" */ 'src/views/messages/View/Messages/Card'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__MESSAGE_DISTRIBUTION,
          component: lazy(() =>
            import(
              /* webpackChunkName: "messages" */ 'src/views/messages/View/MessageDistributions/Card'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__PUSH_TOPIC_TASK,
          component: lazy(() =>
            import(
              /* webpackChunkName: "messages" */ 'src/views/messages/View/PushTopicTasks/Card'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__NOTIF_TEMPLATES__TABLE,
          component: lazy(() => {
            if (!isProvisionerSpecified) {
              return import(
                /* webpackChunkName: "common" */ 'src/views/errors/NotFound'
              );
            }

            return import(
              /* webpackChunkName: "messages" */ 'src/views/notifTemplates/Table'
            );
          }),
        },
        {
          exact: true,
          path: [ROUTE__NOTIF_TEMPLATE__CREATE, ROUTE__NOTIF_TEMPLATE__EDIT],
          component: lazy(() => {
            if (!isProvisionerSpecified) {
              return import(
                /* webpackChunkName: "common" */ 'src/views/errors/NotFound'
              );
            }

            return import(
              /* webpackChunkName: "messages" */ 'src/views/notifTemplates/CreateEdit'
            );
          }),
        },
        // Messages section
        //-------------------------------------------------------------
        // Analytics section
        {
          exact: true,
          path: ROUTE__METRICS,
          component: lazy(() =>
            import(
              /* webpackChunkName: "analytics" */ 'src/views/metrics/MetricsView'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__STORAGE_ANALYTICS,
          component: lazy(() =>
            import(
              /* webpackChunkName: "analytics" */ 'src/views/metrics/Storage'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__REPORTS,
          component: lazy(() =>
            import(
              /* webpackChunkName: "analytics" */ 'src/views/metrics/Reports/List'
            ),
          ),
        },
        {
          exact: true,
          path: ROUTE__REPORTS_CREATE,
          component: lazy(() =>
            import(
              /* webpackChunkName: "analytics" */ 'src/views/metrics/Reports/CreateEdit'
            ),
          ),
        },
        // Analytics section
        //-------------------------------------------------------------
        // Billing
        {
          exact: true,
          path: ROUTE__PACKAGE_CARD,
          component: lazy(() => {
            if (isMegadisk) {
              return import(
                /* webpackChunkName: "megadisk" */ 'src/views/resellers/megadisk/packages/Card'
              );
            }

            // During the building chunks of all of the resellers are built even if
            // you are building the app for one particular reseller.
            // It is not a problem, since those chunks will not be loaded into
            // user's browser.
            // TODO rem unnecessary reseller chunks after the building
            if (isVodafoneTurkey) {
              return import(
                /* webpackChunkName: "vodafoneTurkey" */ 'src/views/resellers/vodafoneTurkey/packages/Card'
              );
            }

            if (isCloudmax) {
              return import(
                /* webpackChunkName: "cloudmax" */ 'src/views/resellers/cloudmax/packages/Card'
              );
            }

            return import(
              /* webpackChunkName: "common" */ 'src/views/errors/NotFound'
            );
          }),
        },
        {
          exact: true,
          path: ROUTE__PACKAGES_TABLE,
          component: lazy(() => {
            if (isMegadisk) {
              return import(
                /* webpackChunkName: "megadisk" */ 'src/views/resellers/megadisk/packages/Table'
              );
            }

            if (isVodafoneTurkey) {
              return import(
                /* webpackChunkName: "vodafoneTurkey" */ 'src/views/resellers/vodafoneTurkey/packages/Table'
              );
            }

            if (isCloudmax) {
              return import(
                /* webpackChunkName: "cloudmax" */ 'src/views/resellers/cloudmax/packages/Table'
              );
            }

            return import(
              /* webpackChunkName: "common" */ 'src/views/errors/NotFound'
            );
          }),
        },
        {
          exact: true,
          path: ROUTE__PROMOCODES_TABLE,
          component: lazy(() => {
            if (!isProvisionerSpecified) {
              return import(
                /* webpackChunkName: "common" */ 'src/views/errors/NotFound'
              );
            }

            return import(
              /* webpackChunkName: "billing" */ 'src/views/promocodes/Table'
            );
          }),
        },
        {
          exact: true,
          path: ROUTE__PROMOCODE__GROUP,
          component: lazy(() => {
            if (!isProvisionerSpecified) {
              return import(
                /* webpackChunkName: "common" */ 'src/views/errors/NotFound'
              );
            }

            return import(
              /* webpackChunkName: "billing" */ 'src/views/promocodes/Group'
            );
          }),
        },
        {
          exact: true,
          path: ROUTE__PROMOCODE__CREATE,
          component: lazy(() => {
            if (!isProvisionerSpecified) {
              return import(
                /* webpackChunkName: "common" */ 'src/views/errors/NotFound'
              );
            }

            return import(
              /* webpackChunkName: "billing" */ 'src/views/promocodes/CreateEdit'
            );
          }),
        },
        {
          exact: true,
          path: ROUTE__PROMOCODE__CARD,
          component: lazy(() => {
            if (!isProvisionerSpecified) {
              return import(
                /* webpackChunkName: "common" */ 'src/views/errors/NotFound'
              );
            }

            return import(
              /* webpackChunkName: "billing" */ 'src/views/promocodes/Card'
            );
          }),
        },
        // Billing
        //-------------------------------------------------------------

        {
          exact: true,
          path: ROUTE__DASHBOARD__MANAGEMENT,
          component: () => <Redirect to={managementSectionDefaultRoute} />,
        },
        {
          exact: true,
          path: ROUTE__DASHBOARD,
          component: () => <Redirect to={managementSectionDefaultRoute} />,
        },
        {
          component: () => <Redirect to={ROUTE__ERROR__404} />,
        },
      ],
    },
    {
      path: '*',
      routes: [
        {
          exact: true,
          path: '/',
          component: () => <Redirect to={ROUTE__DASHBOARD} />,
        },
        {
          component: () => <Redirect to={ROUTE__ERROR__404} />,
        },
      ],
    },
  ];
};

export default createRoutesConfig;

import i18n from 'i18next';

export const showErrorNotif = ({
  enqueueSnackbar,
  error,
  msgKey,
  msg,
  ...restProps
}) => {
  let msgToDisplay;

  if (msg) {
    msgToDisplay = msg;
  } else if (msgKey) {
    msgToDisplay = i18n.t(msgKey);
  } else if (
    error.origError?.response?.status === 404 ||
    error.origError?.response?.status === 403
  ) {
    msgToDisplay = i18n.t('serverError_permissions_not');
  } else if (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    // backend errors are not for human )
    // So, show some abstract error message
    msgToDisplay = i18n.t('notifText__somethingWentWrong');
  } else {
    throw new Error('error message is not specified');
  }

  return enqueueSnackbar(msgToDisplay, {
    variant: 'error',
    ...restProps,
  });
};

export const showSuccessNotif = ({
  enqueueSnackbar,
  msgKey,
  msg,
  ...restProps
}) =>
  enqueueSnackbar(msg || i18n.t(msgKey), {
    variant: 'success',
    ...restProps,
  });

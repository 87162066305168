import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import ScriptTextOutline from 'mdi-material-ui/ScriptTextOutline';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import FolderMultipleOutline from 'mdi-material-ui/FolderMultipleOutline';
import {Users as UsersIcon, Grid as GridIcon} from 'react-feather';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import TimelineOutlinedIcon from '@material-ui/icons/TimelineOutlined';
import StorageOutlinedIcon from '@material-ui/icons/StorageOutlined';
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import AssessmentIcon from '@material-ui/icons/Assessment';

import {
  ROUTE__BUCKETS__TABLE,
  ROUTE__CLIENT_VERSIONS__TABLE,
  ROUTE__FEEDBACKS__LIST,
  ROUTE__KEY_VALUE__LIST,
  ROUTE__PACKAGES_TABLE,
  ROUTE__MESSAGES__CREATE,
  ROUTE__MESSAGES__VIEW__MESSAGES,
  ROUTE__METRICS,
  ROUTE__NOTIF_TEMPLATES__TABLE,
  ROUTE__OFFERS__TABLE,
  ROUTE__PROMOCODES_TABLE,
  ROUTE__SSO_CLIENT__LIST,
  ROUTE__STORAGE_ANALYTICS,
  ROUTE__TENANTS__TABLE,
  ROUTE__USERS,
  ROUTE__USERS_CSV__TABLE,
  ROUTE__REPORTS,
  ROUTE__USERS__ADMINS_TABLE,
} from 'src/constants/routes';
import {getArrayItemIf} from 'src/utils/arrays';
import {
  ROLE__ADMIN,
  ROLE__ADMIN__READ_ONLY,
  ROLE__SUPER_ADMIN,
} from 'src/utils/users/userAdminRoles';

// Defined during the building from the environment.
// eslint-disable-next-line no-undef
const PROVISIONER_ADDR = `${__provisionerUrl__}`;
const isProvisionerSpecified = !!PROVISIONER_ADDR;

// Defined during the building from the environment.
// eslint-disable-next-line no-undef,global-require,import/no-dynamic-require
const resellerName = `${__reseller__}`;
const isMegadisk = resellerName === 'megadisk';
const isVodafoneTurkey = resellerName === 'vodafoneTurkey';
const isCloudmax = resellerName === 'cloudmax';
const isBeecloud = resellerName === 'beelinekz';

export default function createMenuConfig(user) {
  const {role} = user;
  const isAdmin = role === ROLE__ADMIN || role === ROLE__SUPER_ADMIN;
  const isAdminOrReadOnlyAdmin =
    role === ROLE__ADMIN ||
    role === ROLE__ADMIN__READ_ONLY ||
    role === ROLE__SUPER_ADMIN;

  return [
    /// //////////////////////////////////////////////////////////////
    // Analytics
    {
      subheaderKey: 'appSection__Analytics__title',
      items: [
        {
          titleKey: 'appSection__metrics__title',
          icon: TimelineOutlinedIcon,
          href: ROUTE__METRICS,
        },
        {
          titleKey: 'appSection__storageAnalytics__title',
          icon: StorageOutlinedIcon,
          href: ROUTE__STORAGE_ANALYTICS,
        },
        isBeecloud && {
          titleKey: 'no_filename:analytics_reportsCSV',
          icon: AssessmentIcon,
          href: ROUTE__REPORTS,
        },
      ].filter(item => item),
    },
    /// //////////////////////////////////////////////////////////////
    // Manage
    {
      subheaderKey: 'appSection__Management__title',
      items: [
        {
          titleKey: 'appSection__users__title',
          icon: UsersIcon,
          href: ROUTE__USERS,
        },
        {
          titleKey: 'appSection__feedbacks__title',
          icon: FeedbackOutlinedIcon,
          href: ROUTE__FEEDBACKS__LIST,
        },
        {
          titleKey: 'appSection__clientVersions__title',
          icon: GridIcon,
          href: ROUTE__CLIENT_VERSIONS__TABLE,
        },
        {
          titleKey: 'appSection_users_reports_title',
          icon: AssessmentIcon,
          href: ROUTE__USERS_CSV__TABLE,
        },
      ],
    },
    /// //////////////////////////////////////////////////////////////
    // Billing

    ...getArrayItemIf(
      {
        subheaderKey: 'appSection__billing__title',
        items: [
          ...getArrayItemIf(
            {
              titleKey: 'appSection__packages__title',
              icon: MonetizationOnOutlinedIcon,
              href: ROUTE__PACKAGES_TABLE,
            },
            isMegadisk || isVodafoneTurkey || isCloudmax,
          ),
          {
            titleKey: 'appSection__promocodes__title',
            icon: DeveloperModeIcon,
            href: ROUTE__PROMOCODES_TABLE,
          },
        ],
      },
      isProvisionerSpecified,
    ),

    /// //////////////////////////////////////////////////////////////
    // Messages
    {
      subheaderKey: 'appSection__Messages__title',
      items: [
        {
          titleKey: 'appSection__Messages__Create__title',
          icon: RateReviewOutlinedIcon,
          href: ROUTE__MESSAGES__CREATE,
        },
        {
          titleKey: 'appSection__Messages__View__title',
          icon: ListAltOutlinedIcon,
          href: ROUTE__MESSAGES__VIEW__MESSAGES,
        },
        ...getArrayItemIf(
          {
            titleKey: 'appSection__NotificationTemplates__title',
            icon: FeaturedPlayListOutlinedIcon,
            href: ROUTE__NOTIF_TEMPLATES__TABLE,
          },
          isProvisionerSpecified,
        ),
      ],
    },
    /// //////////////////////////////////////////////////////////////
    // Content
    {
      subheaderKey: 'appSection__Content__title',
      items: [
        ...getArrayItemIf(
          {
            titleKey: 'appSection__keyValue__title',
            icon: VpnKeyOutlinedIcon,
            href: ROUTE__KEY_VALUE__LIST,
          },
          isAdmin,
        ),
        ...getArrayItemIf(
          {
            titleKey: 'appSection__ssoClients__title',
            icon: AssignmentIndOutlinedIcon,
            href: ROUTE__SSO_CLIENT__LIST,
          },
          isAdmin,
        ),
        {
          titleKey: 'appSection__offers__title',
          icon: ScriptTextOutline,
          href: ROUTE__OFFERS__TABLE,
        },
      ],
    },
    /// //////////////////////////////////////////////////////////////
    // Admin
    {
      subheaderKey: 'appSection__Admin__title',
      items: [
        ...getArrayItemIf(
          {
            titleKey: 'appSection_admins',
            icon: AssignmentIndOutlinedIcon,
            href: ROUTE__USERS__ADMINS_TABLE,
          },
          isAdminOrReadOnlyAdmin,
        ),
        ...getArrayItemIf(
          {
            titleKey: 'appSection__tenants__title',
            icon: RecentActorsOutlinedIcon,
            href: ROUTE__TENANTS__TABLE,
          },
          isAdminOrReadOnlyAdmin,
        ),
        ...getArrayItemIf(
          {
            titleKey: 'appSection__buckets__title',
            icon: FolderMultipleOutline,
            href: ROUTE__BUCKETS__TABLE,
          },
          isAdminOrReadOnlyAdmin,
        ),
      ],
    },
  ];
}

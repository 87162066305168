// Backend uses underscore in its responses
/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';

import {getValue, isRejected} from 'src/utils/promise';
import {wait} from 'src/utils/misc';
import {downloadFileByUrl} from 'src/utils/browser';
import {getUsersCountPerStorageUsageMetric} from 'src/constants/metrics';
import {hiddenFilterUiValue2BackendValue as promocodeHiddenFilterUiValue2BackendValue} from 'src/views/promocodes/hiddenFilterOptions';
import decodeSsoClient from './ssoClients/decode';
import encodeSsoClient from './ssoClients/encode';
import {
  decode as decodeFeedback,
  normalizeOrderBy as feedbackNormalizeOrderBy,
} from './feedbacks/decode';
import {
  decode as decodeUser,
  normalizeOrderBy as usersNormalizeOrderBy,
} from './users/decode';
import {decode as decodeTenant} from './tenants/decode';
import encodeClientVersion from './clientVersions/encode';
import encodeOffer from './offers/encode';

const configs = JSON.parse(window.localStorage.getItem('configs'));

// The following __variables__ is defined during the build process.
/* eslint-disable no-undef */
const BACKEND_ADDR =
  configs && configs.backendUrl ? configs.backendUrl : `${__backendUrl__}`;
const PROVISIONER_ADDR =
  configs && configs.provisionerUrl
    ? configs.provisionerUrl
    : `${__provisionerUrl__}`;
const APP_VERSION = `${__version__}`;
const resellerName = `${__reseller__}`;
/* eslint-enable no-undef */

const isCloudmax = resellerName === 'cloudmax';

const BACKEND_API2 = `${BACKEND_ADDR}/api/2`;
const BACKEND_API3 = `${BACKEND_ADDR}/api/3`;

const ACCESS_TOKEN_HEADER_NAME = 'Mountbit-Auth';
const RESPONSE_HEADER__TOTAL_COUNT = 'X-Total-Count';

const SSO_CLIENTS_PATH = `${BACKEND_API2}/sso/clients/`;
const CLIENT_VERSIONS_PATH = `${BACKEND_API2}/soft/clients`;
const OFFERS_PATH = `${BACKEND_API2}/offers`;
const TENANTS_PATH = `${BACKEND_API3}/admin_resources/tenants`;
const BUCKETS_PATH = `${BACKEND_API3}/storage/buckets`;
const NOTIF_TEMPLATES_PATH = `${PROVISIONER_ADDR}/provisioning-api/0/admin_resources/notification_templates`;
const megadiskPackagesPath = `${PROVISIONER_ADDR}/provisioning-api/0/admin_resources/megadisk/packages`;
const vodafoneTurkeyPackagesPath = `${PROVISIONER_ADDR}/provisioning-api/0/vodafone/subscriptions`;
const cloudmaxPackagesPath = `${PROVISIONER_ADDR}/provisioning-api/0/cloudmax/packages`;
const promocodesPath = `${PROVISIONER_ADDR}/provisioning-api/0/admin_resources/promocodes`;
const promocodesGroupsPath = `${PROVISIONER_ADDR}/provisioning-api/0/admin_resources/promo_code_groups`;
const provisionerServicesPath = `${PROVISIONER_ADDR}/provisioning-api/0/admin_resources/services`;

const getErrorMessage = error => {
  const errorData = error?.response?.data;
  if (errorData) {
    return errorData.description || errorData;
  }

  return error.message;
};

const createOffsetLimitParams = ({page, pageSize}) => ({
  offset: page === undefined || page === null ? undefined : page * pageSize,
  limit: pageSize,
});

const normalizeOrderDirection = orderDirection => {
  if (!orderDirection) {
    return undefined;
  }

  return orderDirection === 'asc' ? 'ASCENDING' : 'DESCENDING';
};

const createOrderByMinus = (sortField, sortDirection) =>
  sortField ? `${sortDirection === 'desc' ? '-' : ''}${sortField}` : undefined;

const getTotalCountFrom = response =>
  Number(response.headers[RESPONSE_HEADER__TOTAL_COUNT.toLowerCase()]);

const createError = error => {
  const message = getErrorMessage(error);
  const newError = new Error(message);
  newError.origError = error;
  return newError;
};

const createProvisionerServiceNameFrom = promocodeName => `${promocodeName}`;

export default class ApiService {
  static axi = axios.create();

  //---------------------------------------------------------------------------
  // Initialization

  static requestInterceptor = null;

  static responseInterceptor = null;

  static init(unauthCallback) {
    // Add a request interceptor
    ApiService.requestInterceptor = ApiService.axi.interceptors.request.use(
      config => {
        // eslint-disable-next-line no-param-reassign
        config.headers.common[
          'Mountbit-User-Agent'
        ] = `web-admin v${APP_VERSION}`;
        return config;
      },
    );

    // Add a response interceptor
    ApiService.responseInterceptor = ApiService.axi.interceptors.response.use(
      response => response,
      error => {
        let error2return = error;

        if (error.response) {
          if (error.response.status === 401) {
            if (unauthCallback) {
              unauthCallback();
            }
          }

          error2return = createError(error);
        }

        return Promise.reject(error2return);
      },
    );
  }

  static uninit() {
    if (ApiService.requestInterceptor) {
      ApiService.axi.interceptors.request.eject(ApiService.requestInterceptor);
      ApiService.requestInterceptor = null;
    }

    if (ApiService.responseInterceptor) {
      ApiService.axi.interceptors.response.eject(
        ApiService.responseInterceptor,
      );
      ApiService.responseInterceptor = null;
    }

    // AZA:
    // Looks like the ejects above do not work.
    // So, recreate axios instance from scratch.
    ApiService.axi = axios.create();
  }

  static setAccessToken(token) {
    if (token) {
      ApiService.axi.defaults.headers.common[ACCESS_TOKEN_HEADER_NAME] = token;
    }
  }

  static clearAccessToken() {
    delete ApiService.axi.defaults.headers.common[ACCESS_TOKEN_HEADER_NAME];
  }

  // Initialization
  //---------------------------------------------------------------------------

  //= =======================================
  // Request cancellation

  static createCancelToken() {
    const {CancelToken} = axios;

    return CancelToken.source();
  }

  static isRequestCancelled(error) {
    return axios.isCancel(error);
  }

  // Request cancellation
  //= =======================================

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Auth

  // If called without the parameter, then returns information about
  // currently logged in user
  static async getUserAccount(id) {
    const response = await ApiService.axi.get(`${BACKEND_API2}/accounts/get`, {
      params: {user_id: id},
    });

    return response.data;
  }

  static async login(username, password) {
    let loginResponse;

    try {
      loginResponse = await ApiService.axi.post(
        `${BACKEND_API2}/accounts/login`,
        {
          email: username,
          password,
        },
      );
    } catch (error) {
      if (error.origError) {
        const errCode = error.origError.response.data?.code;

        let errMsgTranKey;
        if (errCode === 'Unauthorized') {
          errMsgTranKey = 'login__error__wrongEmailOrPassword';
        } else if (errCode === 'IncorrectAttemptsLocked') {
          errMsgTranKey = 'login__error__tooManyAttemptsFailed';
        } else {
          errMsgTranKey = getErrorMessage(error);
        }

        throw new Error(errMsgTranKey);
      }

      throw error;
    }

    const {token} = loginResponse.data;
    ApiService.setAccessToken(token);

    // Request user info
    const user = await ApiService.getUserAccount();

    return {token, user};
  }

  static async logout() {
    let response;

    try {
      response = await ApiService.axi.post(`${BACKEND_API2}/accounts/logout`);
    } finally {
      ApiService.clearAccessToken();
    }

    return response.data;
  }

  // Auth
  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //---------------------------------------------------------------------------
  // Helper methods

  static async deleteEntity(url) {
    const response = await ApiService.axi.delete(url);

    return response.data;
  }

  static async getEntitiesByIds({
    ids,
    getEntityApiMethod,
    retValOnError = null,
  }) {
    const requests = ids.map(id => getEntityApiMethod(id));

    const promises = await Promise.allSettled(requests);

    return promises.map(p => {
      if (isRejected(p)) {
        return retValOnError;
      }

      return getValue(p);
    });
  }

  // Helper methods
  //---------------------------------------------------------------------------

  /// //////////////////////////////////////////////////////////////////////////
  // Key-Value

  static async getKeyValuePairs() {
    const response = await ApiService.axi.get(`${BACKEND_API2}/keyvalue/`);

    return response.data;
  }

  static async getKeyValuePair(key) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/keyvalue/${key}`,
    );

    return response.data;
  }

  static async createKeyValuePair(key, value, rights) {
    const response = await ApiService.axi.post(
      `${BACKEND_API2}/keyvalue/${key}`,
      {
        value,
        rights,
      },
    );

    return response.data;
  }

  static async updateKeyValuePair(key, value, rights) {
    return ApiService.createKeyValuePair(key, value, rights);
  }

  static deleteKeyValuePair(key) {
    return ApiService.deleteEntity(`${BACKEND_API2}/keyvalue/${key}`);
  }

  // Key-Value
  /// //////////////////////////////////////////////////////////////////////////

  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // SSO clients

  static async getSsoClients() {
    const response = await ApiService.axi.get(SSO_CLIENTS_PATH);

    const origData = response.data?.clients;
    let normalizedData = [];
    if (Array.isArray(origData)) {
      normalizedData = origData.map(decodeSsoClient);
    }

    return normalizedData;
  }

  static async getSsoClient(id) {
    const response = await ApiService.axi.get(`${SSO_CLIENTS_PATH}/${id}`);

    return decodeSsoClient(response.data);
  }

  static async createSsoClient(clientData) {
    const backendData = encodeSsoClient(clientData);

    const response = await ApiService.axi.post(SSO_CLIENTS_PATH, backendData);

    return response.data;
  }

  static async updateSsoClient(updatedClient) {
    const {id: clientId} = updatedClient;
    const backendData = encodeSsoClient(updatedClient);

    const response = await ApiService.axi.post(
      `${SSO_CLIENTS_PATH}/${clientId}/edit/`,
      backendData,
    );

    return response.data;
  }

  static deleteSsoClient(id) {
    return ApiService.deleteEntity(`${SSO_CLIENTS_PATH}/${id}`);
  }

  // SSO clients
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  /// //////////////////////////////////////////////////////////////////////////
  // Feedbacks

  static async getFeedbacks({
    page,
    pageSize,
    orderBy,
    orderDirection,
    userId,
  } = {}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/accounts/feedback_list/`,
      {
        params: {
          ...createOffsetLimitParams({page, pageSize}),
          order_field: feedbackNormalizeOrderBy(orderBy),
          order_direction: normalizeOrderDirection(orderDirection),
          user_id: userId,
        },
      },
    );

    const origData = response.data?.data;
    let normalizedData = [];
    if (Array.isArray(origData)) {
      normalizedData = origData.map(decodeFeedback);
    }

    return {
      ...response.data,
      data: normalizedData,
    };
  }

  static async sendCommentQAFeedback(id, comment) {
    const response = await ApiService.axi.post(
      `${BACKEND_API2}/admin/edit_feedback/`,
      {id, comment},
    );

    const origData = response.data;
    const normalizedData = decodeFeedback(origData);

    return normalizedData;
  }

  static async getFeedback(id) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/accounts/feedback_list/${id}`,
    );

    const origData = response.data;
    const normalizedData = decodeFeedback(origData);

    return normalizedData;
  }

  // msgType - Required. One of email/sms/notification_push.
  static async replyFeedback({
    feedbackId,
    replyText,
    msgType,
    subject,
    recipient,
  }) {
    const response = await ApiService.axi.post(
      `${BACKEND_API2}/admin/reply_feedback/`,
      {
        id: feedbackId,
        answer: replyText,
        type: msgType,
        subject,
        recipient,
      },
    );

    return response.data;
  }

  // Feedbacks
  /// //////////////////////////////////////////////////////////////////////////

  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Users

  static async getUsers(
    {
      page,
      pageSize,
      orderBy,
      orderDirection,
      /// ////////////
      // filters
      userId,
      // If not specified, then returns all. True - only active, False - only inactive
      isActive,
      // If not specified, then returns all. True - only deleted, False - only alive
      isDeleted,
      login,
      tenantName,
      userName,
      withLogins,
    } = {},
    isAdmin = false,
  ) {
    const orderByNormalized = usersNormalizeOrderBy(orderBy);
    const getAdminUrl = `${BACKEND_API3}/admin_resources/users?roles__nin=user&roles__nin=virtual`;

    const response = await ApiService.axi.get(
      isAdmin ? getAdminUrl : `${BACKEND_API3}/admin_resources/users`,
      {
        params: {
          ...createOffsetLimitParams({page, pageSize}),
          id:
            userId !== undefined && userId !== null
              ? Number(userId)
              : undefined,
          is_active: isActive,
          is_deleted: isDeleted,
          login_value: login,
          tenant_name: tenantName,
          name: userName,
          order_by: createOrderByMinus(orderByNormalized, orderDirection),
          with_logins: withLogins,
        },
      },
    );

    const origData = response.data._embedded?.users;
    let normalizedData = [];
    if (Array.isArray(origData)) {
      normalizedData = origData.map(decodeUser);
    }

    return {
      data: normalizedData,
    };
  }

  static async getTotalCount(
    {
      orderBy,
      orderDirection,
      /// ////////////
      // filters
      userId,
      // If not specified, then returns all. True - only active, False - only inactive
      isActive,
      // If not specified, then returns all. True - only deleted, False - only alive
      isDeleted,
      login,
      tenantName,
      userName,
      withLogins,
    } = {},
    isAdmin = false,
  ) {
    const orderByNormalized = usersNormalizeOrderBy(orderBy);
    const getAdminUrl = `${BACKEND_API3}/admin_resources/users?roles__nin=user&roles__nin=virtual`;

    const response = await ApiService.axi.head(
      isAdmin ? getAdminUrl : `${BACKEND_API3}/admin_resources/users`,
      {
        params: {
          limit: 1,
          total_count: true,
          id:
            userId !== undefined && userId !== null
              ? Number(userId)
              : undefined,
          is_active: isActive,
          is_deleted: isDeleted,
          login_value: login,
          tenant_name: tenantName,
          name: userName,
          order_by: createOrderByMinus(orderByNormalized, orderDirection),
          with_logins: withLogins,
        },
      },
    );

    return getTotalCountFrom(response);
  }

  static async getRoles() {
    const response = await ApiService.axi.get(
      `${BACKEND_API3}/admin_resources/roles`,
    );

    return response.data;
  }

  static async updateRoles(id, roles) {
    const response = await ApiService.axi.patch(
      `${BACKEND_API3}/admin_resources/users/${id}/roles`,
      {roles},
    );

    return response.data;
  }

  static async getUser(id) {
    const response = await ApiService.axi.get(
      `${BACKEND_API3}/admin_resources/users/${id}`,
    );

    const origData = response.data;
    return decodeUser(origData);
  }

  static async createUser({name, login, password, tenantName}) {
    const response = await ApiService.axi.post(
      `${BACKEND_API2}/admin_resources/users`,
      {name, login, password, tenant_name: tenantName},
    );

    return response.data;
  }

  static deleteUser(id) {
    return ApiService.deleteEntity(
      `${BACKEND_API3}/admin_resources/users/${id}`,
    );
  }

  static async getUserCredentials(userId) {
    const response = await ApiService.axi.get(
      `${BACKEND_API3}/admin_resources/users/${userId}/credentials`,
    );

    return response.data._embedded?.credentials;
  }

  static async getCredentialsForUsers(userIds) {
    return ApiService.getEntitiesByIds({
      ids: userIds,
      getEntityApiMethod: ApiService.getUserCredentials,
      retValOnError: [],
    });
  }

  static async updateUser(id, {isActive, password, features}) {
    const response = await ApiService.axi.patch(
      `${BACKEND_API3}/admin_resources/users/${id}`,
      {is_active: isActive, password, features},
    );

    return response.data;
  }

  static async changeUserPassword(id, newPassword) {
    return ApiService.updateUser(id, {password: newPassword});
  }

  static async changeUserFeatures(id, updatedFeatures) {
    return ApiService.updateUser(id, {features: updatedFeatures});
  }

  // Calling this method triggers download of export file.

  static async getExportedUsers() {
    const response = await ApiService.axi.get(
      `${PROVISIONER_ADDR}/provisioning-api/0/reports/users/`,
    );

    return response?.data._embedded?.reports;
  }

  static async getExportedUser(id) {
    const response = await ApiService.axi.get(
      `${PROVISIONER_ADDR}/provisioning-api/0/reports/users/${id}`,
    );

    return response.data._links.data && response.data._links.data.href;
  }

  static async createReportsUsers(data) {
    const response = await ApiService.axi.post(
      `${PROVISIONER_ADDR}/provisioning-api/0/reports/users/`,
      data,
    );
    return response;
  }

  static async removeReportsUsers(id) {
    await ApiService.axi.delete(
      `${PROVISIONER_ADDR}/provisioning-api/0/reports/users/${id}`,
    );
  }

  static async requestUsersExport() {
    let exportFileUrl;
    let exportFileName;
    let exportFileState = 0;

    const createTaskResponse = await ApiService.axi.post(
      `${BACKEND_API2}/admin_resources/reports/users`,
      {type: 'users'},
    );

    const taskUrl = createTaskResponse.data._links.self.href;

    // Create task

    while (exportFileState < 100) {
      // eslint-disable-next-line no-await-in-loop
      await wait(1000);
      // Get task info
      // eslint-disable-next-line no-await-in-loop
      const taskResponse = await ApiService.axi.get(taskUrl);
      exportFileState = taskResponse.data.progress;
      exportFileUrl =
        exportFileState === 100 && taskResponse.data._links.data.href;
      exportFileName = taskResponse.data.id;
    }

    if (exportFileUrl) {
      downloadFileByUrl({url: exportFileUrl, fileName: exportFileName});
    }
  }

  static async getUserTokens(userId) {
    const response = await ApiService.axi.get(
      `${BACKEND_API3}/admin_resources/users/${userId}/tokens/`,
    );

    return response.data._embedded?.tokens;
  }

  static async getUserContactsBackups(userId) {
    const response = await ApiService.axi.get(
      `${BACKEND_API3}/admin_resources/users/${userId}/contacts/books`,
    );

    return response.data._embedded?.books;
  }

  static async getUserOrders({userId, status}) {
    const response = await ApiService.axi.get(
      `${PROVISIONER_ADDR}/provisioning-api/0/users/${userId}/orders`,
      {
        params: {status: status || undefined},
      },
    );

    return response.data.content;
  }

  static async getUserPromoCodes({userId}) {
    const response = await ApiService.axi.get(
      `${PROVISIONER_ADDR}/provisioning-api/0/users/${userId}/promocodes`,
    );

    return response.data.content;
  }

  static async applyPromoCodeForUser({userId, promoCode}) {
    const response = await ApiService.axi.post(
      `${PROVISIONER_ADDR}/provisioning-api/0/users/${userId}/promocodes`,
      {
        code: promoCode,
      },
    );

    return response.data;
  }

  static async getUserPhotoOperations({
    userId,
    page,
    pageSize,
    /// ////////////
    // filters
    dtStart,
    dtEnd,
  } = {}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/admin_resources/users/${userId}/photos/operations`,
      {
        params: {
          ...createOffsetLimitParams({page, pageSize}),
          total_count: true,
          created__gte: dtStart ? dtStart.valueOf() : undefined,
          created__lte: dtEnd ? dtEnd.valueOf() : undefined,
        },
      },
    );

    const totalCount = getTotalCountFrom(response);
    const origData = response.data._embedded?.operations;

    return {
      count: totalCount,
      data: origData,
    };
  }

  static async getUserPhotoOperation({userId, operationId}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/admin_resources/users/${userId}/photos/operations/${operationId}`,
    );

    return response.data;
  }

  // Calling this method triggers download of export file.
  static async requestUserFileOperationsLog(userId) {
    let exportFileUrl;
    const taskUrl = `${BACKEND_API3}/admin_resources/users/${userId}/events_task`;

    // Create task
    await ApiService.axi.put(taskUrl);

    let tryCount = 0;
    while (tryCount < 60) {
      // eslint-disable-next-line no-await-in-loop
      await wait(1000);
      // Get task info
      // eslint-disable-next-line no-await-in-loop
      const taskResponse = await ApiService.axi.get(taskUrl);
      tryCount += 1;

      if (taskResponse.data?.status === 'completed') {
        exportFileUrl = taskResponse.data._links?.download_url?.href;
        break;
      }
    }

    if (exportFileUrl) {
      downloadFileByUrl({url: exportFileUrl});
    }
  }

  static deleteUserToken(userId, tokenId) {
    return ApiService.deleteEntity(
      `${BACKEND_API3}/admin_resources/users/${userId}/tokens/${tokenId}`,
    );
  }

  // Returns key necessary for login as the specified user.
  static async getUserLoginKey(userId) {
    const response = await ApiService.axi.post(
      `${BACKEND_API2}/admin/accounts_generate_login_key/`,
      {
        user_id: userId,
      },
    );

    const data = response.data;
    if (!Array.isArray(data) || data.length < 2) {
      throw new Error(
        `get user login key: userId="${userId}", unexpected response: ${data.toString()}`,
      );
    }

    return data[1];
  }

  static async getUserMessages({
    userId,
    type,
    page,
    pageSize,
    dtStart,
    dtEnd,
  } = {}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/sending/messages`,
      {
        params: {
          ...createOffsetLimitParams({page, pageSize}),
          total_count: true,
          user_id:
            userId !== undefined && userId !== null
              ? Number(userId)
              : undefined,
          type,
          created__gte: dtStart ? dtStart.valueOf() : undefined,
          created__lt: dtEnd ? dtEnd.valueOf() : undefined,
        },
      },
    );

    const totalCount = getTotalCountFrom(response);
    const origData = response.data._embedded?.messages;

    return {
      count: totalCount,
      data: origData,
    };
  }

  static async getUserMessage(id) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/sending/messages/${id}`,
    );

    return response.data;
  }

  static async getPushHeartbeats({userId, subscriptionId} = {}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API3}/admin_resources/users/${userId}/push_subscriptions/${subscriptionId}/heartbeats`,
    );

    return response.data._embedded?.heartbeats;
  }

  static async getUserPushSubscriptions({userId} = {}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API3}/admin_resources/users/${userId}/push_subscriptions`,
    );

    const subscriptions = response.data._embedded?.subscriptions;

    const heartbeatsRequests = subscriptions.map(({id: subscriptionId}) =>
      ApiService.getPushHeartbeats({userId, subscriptionId}),
    );

    const heartbeatsRequestsPromises = await Promise.allSettled(
      heartbeatsRequests,
    );

    const heartbeats = heartbeatsRequestsPromises.map(p => {
      if (isRejected(p)) {
        return [];
      }

      return getValue(p);
    });

    const updatedSubscriptions = subscriptions.map(
      (subscription, subscriptionIndex) => {
        const updatedSubscr = {...subscription};
        updatedSubscr.heartbeats = heartbeats[subscriptionIndex];
        return updatedSubscr;
      },
    );

    return updatedSubscriptions;
  }

  // https://disk-api-qa.megafon.ru/backend_docs/rest/api/3/megadisk_pkg/users_app/UserTypeChecker/index.html#post
  // Check user type changed to mgf?
  static async checkUserType(userId) {
    const response = await ApiService.axi.post(
      `${BACKEND_API3}/admin_resources/users/${userId}/user_type_checker`,
    );

    return response.data;
  }

  // http://disk-api-qa.megafon.ru/provisioner_docs/rest/api/0/megadisk_app/UserPackagesChecker/index.html#post
  // Necessary for activate lost packages?
  static async checkUserPackages(userId) {
    const response = await ApiService.axi.post(
      `${PROVISIONER_ADDR}/provisioning-api/0/users/${userId}/user_packages_checker`,
    );

    return response.data;
  }

  // Users
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Events

  static async getEvents({page, pageSize, userId, dtStart, dtEnd} = {}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/admin_resources/events/`,
      {
        params: {
          skip:
            page === undefined || page === null ? undefined : page * pageSize,
          limit: pageSize,
          user_id: userId,
          from_timestamp: dtStart ? dtStart.valueOf() : undefined,
          to_timestamp: dtEnd ? dtEnd.valueOf() : undefined,
        },
      },
    );

    return response.data;
  }

  // Events
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //= ===========================================================================
  // Client versions

  static async getClientVersions() {
    const response = await ApiService.axi.get(CLIENT_VERSIONS_PATH);

    return response.data._embedded?.clients;
  }

  static async getClientVersion(id) {
    const response = await ApiService.axi.get(`${CLIENT_VERSIONS_PATH}/${id}`);

    return response.data;
  }

  static async createClientVersion(data) {
    const preparedData = encodeClientVersion(data);
    const response = await ApiService.axi.post(
      CLIENT_VERSIONS_PATH,
      preparedData,
    );

    return response.data;
  }

  static async updateClientVersion(updatedData) {
    const {clientType} = updatedData;
    const preparedData = encodeClientVersion(updatedData);

    const response = await ApiService.axi.put(
      `${CLIENT_VERSIONS_PATH}/${clientType}`,
      preparedData,
    );

    return response.data;
  }

  static deleteClientVersion(id) {
    return ApiService.deleteEntity(`${CLIENT_VERSIONS_PATH}/${id}`);
  }

  // Client versions
  //= ===========================================================================

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Offers

  static async getOffers() {
    const response = await ApiService.axi.get(OFFERS_PATH);

    return response.data._embedded?.offers;
  }

  static async getOffer(id) {
    const response = await ApiService.axi.get(`${OFFERS_PATH}/${id}`);

    return response.data;
  }

  static async getOfferContent(id) {
    const response = await ApiService.axi.get(`${OFFERS_PATH}/${id}/content/`);

    return response.data;
  }

  static async getOfferWithContent(id) {
    const offer = await ApiService.getOffer(id);
    if (!offer.url) {
      // if doesn't have external content
      offer.content = await ApiService.getOfferContent(id);
    }

    return offer;
  }

  static async createOffer({tenant, lang, type, url, content}) {
    const preparedData = encodeOffer({tenant, lang, type, url, content});
    const response = await ApiService.axi.post(OFFERS_PATH, preparedData);

    return response.data;
  }

  static async updateOffer({id, tenant, lang, type, url, content}) {
    const preparedData = encodeOffer({id, tenant, lang, type, url, content});

    const response = await ApiService.axi.patch(
      `${OFFERS_PATH}/${id}`,
      preparedData,
    );

    return response.data;
  }

  static deleteOffer(id) {
    return ApiService.deleteEntity(`${OFFERS_PATH}/${id}`);
  }

  // Offers
  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //= ==========================================================================
  // Tenants

  static async getTenants() {
    const response = await ApiService.axi.get(TENANTS_PATH);

    const origData = response.data._embedded?.tenants;
    let normalizedData = [];
    if (Array.isArray(origData)) {
      normalizedData = origData.map(decodeTenant);
    }

    return normalizedData;
  }

  static async getTenant(id) {
    const response = await ApiService.axi.get(`${TENANTS_PATH}/${id}`);

    return decodeTenant(response.data);
  }

  static async createTenant({name, description, domain, feedbackEmail}) {
    const response = await ApiService.axi.post(TENANTS_PATH, {
      name,
      description,
      domain,
      feedback_email: feedbackEmail,
    });

    return response.data;
  }

  static async updateTenant({id, description, domain, feedbackEmail}) {
    const response = await ApiService.axi.patch(`${TENANTS_PATH}/${id}`, {
      description,
      domain,
      feedback_email: feedbackEmail,
    });

    return response.data;
  }

  static async deleteTenant(id) {
    return ApiService.deleteEntity(`${TENANTS_PATH}/${id}`);
  }

  // Tenants
  //= ==========================================================================

  //* ***************************************************************************
  // Buckets

  static async getBuckets() {
    const response = await ApiService.axi.get(BUCKETS_PATH);

    return response.data._embedded?.buckets;
  }

  static async getBucket(id) {
    const response = await ApiService.axi.get(`${BUCKETS_PATH}/${id}`);

    return response.data;
  }

  static async createBucket({
    name,
    type,
    isReadOnly,
    connectionParams,
    tenantId,
    category,
  }) {
    const response = await ApiService.axi.post(BUCKETS_PATH, {
      name,
      type,
      category,
      is_read_only: isReadOnly,
      connection_params: connectionParams,
      tenant_id: tenantId ?? undefined,
    });

    return response.data;
  }

  static async updateBucket({
    id,
    name,
    type,
    isReadOnly,
    connectionParams,
    category,
  }) {
    const response = await ApiService.axi.patch(`${BUCKETS_PATH}/${id}`, {
      name,
      type,
      category,
      is_read_only: isReadOnly,
      connection_params: connectionParams,
    });

    return response.data;
  }

  static async deleteBucket(id) {
    return ApiService.deleteEntity(`${BUCKETS_PATH}/${id}`);
  }

  // Buckets
  //* ***************************************************************************

  //---------------------------------------------------------------------------
  // Push topics

  static async getPushTopics() {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/sending/push_topics`,
    );

    return response.data._embedded?.push_topics;
  }

  static async getPushTopicTasks({page, pageSize} = {}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/sending/push_topic_tasks`,
      {
        params: {
          ...createOffsetLimitParams({page, pageSize}),
          total_count: true,
        },
      },
    );

    const totalCount = getTotalCountFrom(response);
    const origData = response.data._embedded?.push_topic_tasks;

    return {
      count: totalCount,
      data: origData,
    };
  }

  static async getPushTopicTask(id) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/sending/push_topic_tasks/${id}`,
    );

    return response.data;
  }

  // Push topics
  //---------------------------------------------------------------------------

  //* **************************************************************************
  // Send messages to users

  // Can be used to send single sms, email or push notif.
  static async genericSendMessage({
    recipient,
    msgType,
    body,
    data,
    analytics_label,
  }) {
    const response = await ApiService.axi.post(
      `${BACKEND_API2}/sending/messages`,
      {
        user_id: 0,
        recipient,
        type: msgType,
        body,
        data,
        analytics_label,
      },
    );

    return response.data;
  }

  static async sendSms({phone, text}) {
    return ApiService.genericSendMessage({
      recipient: phone,
      msgType: 'sms',
      body: text,
    });
  }

  static async sendEmail({email, subject, text}) {
    return ApiService.genericSendMessage({
      recipient: email,
      msgType: 'email',
      body: text,
      data: {
        subject,
      },
    });
  }

  static async sendPushNotif({device, title, text, data, analytics_label}) {
    const pushData = this.preparePushDataObject(data);

    return ApiService.genericSendMessage({
      recipient: device,
      msgType: 'notification_push',
      body: text,
      data: {
        subject: title,
        ...pushData,
      },
      analytics_label,
    });
  }

  // Can be used to send multiple sms, email or push notifications.
  static async genericSendMessageDistribution({
    userIds,
    msgType,
    body,
    data,
    sendDt,
    sendToDeleted,
  }) {
    const response = await ApiService.axi.post(
      `${BACKEND_API2}/sending/message_distributions`,
      {
        user_ids: typeof userIds === 'string' ? userIds : userIds.join(' '),
        type: msgType,
        body,
        data,
        send_time: sendDt ? sendDt.toISOString() : undefined,
        send_to_deleted_users: sendToDeleted,
      },
    );

    return response.data;
  }

  static async sendSmsDistribution({userIds, text, sendDt, sendToDeleted}) {
    return ApiService.genericSendMessageDistribution({
      userIds,
      msgType: 'sms',
      body: text,
      sendDt,
      sendToDeleted,
    });
  }

  static async sendEmailDistribution({userIds, subject, text, sendDt}) {
    return ApiService.genericSendMessageDistribution({
      userIds,
      msgType: 'email',
      body: text,
      data: {
        subject,
      },
      sendDt,
    });
  }

  static preparePushDataObject(keyValArr) {
    if (Array.isArray(keyValArr)) {
      const pushData = {};

      keyValArr.forEach(({key, value}) => {
        pushData[key] = value;
      });

      return pushData;
    }

    return undefined;
  }

  static async sendPushNotifDistribution({
    userIds,
    title,
    text,
    data,
    sendDt,
    analytics_label,
  }) {
    const pushData = this.preparePushDataObject(data);

    return ApiService.genericSendMessageDistribution({
      userIds,
      msgType: 'notification_push',
      body: text,
      data: {
        subject: title,
        ...pushData,
        analytics_label,
      },
      sendDt,
    });
  }

  static async sendPushTopicDistribution({
    topic,
    title,
    text,
    data,
    sendDt,
    analytics_label,
  }) {
    const response = await ApiService.axi.post(
      `${BACKEND_API2}/sending/push_topic_tasks`,
      {
        topic_name: topic,
        payload: {
          notification: {
            title,
            body: text,
          },
          data: isEmpty(data) ? undefined : this.preparePushDataObject(data),
          analytics_label,
        },
        frequency: 0,
        next_send: sendDt ? sendDt.valueOf() : undefined,
        is_active: true,
      },
    );

    return response.data;
  }

  static async getMessageDistributions({
    type,
    page,
    pageSize,
    dtStart,
    dtEnd,
  } = {}) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/sending/message_distributions`,
      {
        params: {
          ...createOffsetLimitParams({page, pageSize}),
          total_count: true,
          type,
          created__gte: dtStart ? dtStart.toISOString() : undefined,
          created__lt: dtEnd ? dtEnd.toISOString() : undefined,
        },
      },
    );

    const totalCount = getTotalCountFrom(response);
    const origData = response.data._embedded?.distributions;

    return {
      count: totalCount,
      data: origData,
    };
  }

  static async getMessageDistribution(id) {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/sending/message_distributions/${id}`,
    );

    return response.data;
  }

  // Send messages to users
  //* **************************************************************************

  //---------------------------------------------------------------------------
  // Metrics

  static async getMetricsNext(url) {
    const rspNext = await ApiService.axi.get(url);

    let dataObj = rspNext.data._embedded.metrics;

    if (rspNext.data._links.next && rspNext.data._links.next.href) {
      const rsp = await ApiService.getMetricsNext(
        rspNext.data._links.next.href,
      );
      dataObj = [...dataObj, ...rsp];
    }

    return dataObj;
  }

  static async getMetrics() {
    const response = await ApiService.axi.get(
      `${BACKEND_API2}/analytics/metrics/`,
    );

    let dataObj = response.data._embedded.metrics;

    if (response.data._links.next && response.data._links.next.href) {
      const rspNext = await ApiService.getMetricsNext(
        response.data._links.next.href,
      );
      dataObj = [...dataObj, ...rspNext];
    }

    return Object.values(dataObj);
  }

  static async getMetricData({dtStart, dtEnd, metric, period, region}) {
    const url = new URL(`${BACKEND_API2}/analytics/metrics/${metric}`);
    const queryParams = {
      time__gte: dtStart ? dtStart.unix() : undefined,
      time__lt: dtEnd ? dtEnd.unix() : undefined,
      period,
      region,
    };

    const searchParams = new URLSearchParams(queryParams);
    url.search = searchParams.toString();

    const rsp = await ApiService.axi.get(url.href);

    const data = rsp.data._embedded?.points;

    data.forEach(item => {
      const {value} = item;

      if (typeof value === 'string') {
        // data can be large. Do not want to copy the data.
        // eslint-disable-next-line no-param-reassign
        item.value = Number.parseFloat(value);
      }
    });

    return data;
  }

  // Тренд - изменение значения в процентах за последние 30 дней
  // Returns [value, trend]
  static async getMetricValueAndTrend(metric) {
    const dtStart = moment().subtract(30, 'days').startOf('day');
    const dtEnd = moment().subtract(1, 'day').endOf('day');
    const dtStartEndOfDay = moment(dtStart).endOf('day');
    const dtEndStartOfDay = moment(dtEnd).startOf('day');

    const data = await ApiService.getMetricData({
      dtStart,
      dtEnd,
      metric,
    });

    if (isEmpty(data) || !Array.isArray(data)) {
      return [null, null];
    }

    const firstPoint = data[0];
    const lastPoint = data[data.length - 1];

    if (
      lastPoint.time !== dtEndStartOfDay.unix() &&
      !(
        lastPoint.time > dtEndStartOfDay.unix() && lastPoint.time < dtEnd.unix()
      ) &&
      // CloudMax special case: you are requesting yesterday's data, but receive data for the day before yesterday
      !(
        isCloudmax &&
        lastPoint.time > dtEndStartOfDay.subtract(1, 'day').unix() &&
        lastPoint.time < dtEnd.subtract(1, 'day').unix()
      )
    ) {
      // if the last point doesn't correspond to yesterday, then we don't know
      // current value => don't know the trend
      return [null, null];
    }

    const currentValue = lastPoint.value;

    if (
      firstPoint.time !== dtStart.unix() &&
      !(
        firstPoint.time > dtStart.unix() &&
        firstPoint.time < dtStartEndOfDay.unix()
      ) &&
      // CloudMax special case: you are requesting yesterday's data, but receive data for the day before yesterday
      !(
        isCloudmax &&
        firstPoint.time > dtStart.subtract(1, 'day').unix() &&
        firstPoint.time < dtStartEndOfDay.subtract(1, 'day').unix()
      )
    ) {
      // if the first point is not dtStart, then we don't know
      // the trend for the last 30 days
      return [currentValue, null];
    }

    const initialValue = firstPoint.value;
    const trend = ((currentValue - initialValue) / initialValue) * 100;

    return [currentValue, trend];
  }

  // Possible usage ranges are specified here: ./metrics/usageRanges.js
  // Requests latest metric data (for yesterday).
  // Returns null if no data for the specified range.
  static async getUsersCountForUsageRange(usageRange) {
    const metric = getUsersCountPerStorageUsageMetric(usageRange);
    const yesterday = moment().subtract(isCloudmax ? 2 : 1, 'day');

    const data = await ApiService.getMetricData({
      metric,
      dtStart: moment(yesterday).startOf('day'),
      dtEnd: moment(yesterday).endOf('day'),
    });

    return Array.isArray(data) && data.length ? data[0].value : null;
  }

  static getUsersCountPerUsageRange(ranges) {
    const requests = ranges.map(rng => this.getUsersCountForUsageRange(rng));

    return Promise.all(requests);
  }

  // Metrics
  //---------------------------------------------------------------------------

  //* **************************************************************************
  // Notification templates

  // notifType - msg about what event,
  // msgType - sms, email, push
  static async getNotifTemplates({notifType, msgType} = {}) {
    const response = await ApiService.axi.get(NOTIF_TEMPLATES_PATH, {
      params: {
        total_count: true,
        type: notifType,
        message_type: msgType,
      },
    });

    return response.data?._embedded?.notification_templates;
  }

  static async deleteNotifTemplate(id) {
    return ApiService.deleteEntity(`${NOTIF_TEMPLATES_PATH}/${id}`);
  }

  static async getNotifTemplateTypes() {
    const response = await ApiService.axi.get(
      `${PROVISIONER_ADDR}/provisioning-api/0/admin_resources/admin_choices`,
      {
        params: {
          total_count: true,
          group: 'notification_type_choices',
        },
      },
    );

    const origData = response.data?._embedded?.choices;

    return origData.map(item => ({...item, name: item.name.replace(/.$/, '')}));
  }

  static notifTemplate_convertParamsFrontend2Backend({
    notifType,
    msgType,
    days,
    lang,
    subject,
    body,
    googleAnalyticsEventName,
    isActive,
  }) {
    return {
      type: notifType,
      message_type: msgType,
      list_of_days: isEmpty(days) ? [0] : days,
      language: lang,
      subject_template: subject,
      body_template: body,
      event_template: googleAnalyticsEventName,
      active: isActive,
    };
  }

  static async createNotifTemplate(params) {
    const backendParams = ApiService.notifTemplate_convertParamsFrontend2Backend(
      params,
    );

    const response = await ApiService.axi.post(
      NOTIF_TEMPLATES_PATH,
      backendParams,
    );

    return response.data;
  }

  static async updateNotifTemplate(id, params) {
    const backendParams = ApiService.notifTemplate_convertParamsFrontend2Backend(
      params,
    );
    const response = await ApiService.axi.put(
      `${NOTIF_TEMPLATES_PATH}/${id}`,
      backendParams,
    );

    return response.data;
  }

  // Notification templates
  //* **************************************************************************

  //= ===========================================================================
  // Billing

  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // Megadisk packages
  // MEGADISK ONLY

  static async megadiskGetPackages({page, pageSize} = {}) {
    const response = await ApiService.axi.get(megadiskPackagesPath, {
      params: {
        ...createOffsetLimitParams({page, pageSize}),
        total_count: true,
      },
    });

    const totalCount = getTotalCountFrom(response);
    const data = response.data?._embedded?.packages;

    return {
      count: totalCount,
      data,
    };
  }

  static async megadiskGetPackage(id) {
    const response = await ApiService.axi.get(`${megadiskPackagesPath}/${id}`);

    return response.data;
  }

  static async megadiskUpdatePackage(id, values) {
    const response = await ApiService.axi.patch(
      `${megadiskPackagesPath}/${id}`,
      values,
    );

    return response.data;
  }

  static async megadiskDeletePackage(id) {
    return ApiService.deleteEntity(`${megadiskPackagesPath}/${id}`);
  }

  // Megadisk packages
  //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //= ================================================================
  // Vodafone Turkey packages
  // VODAFONE TURKEY ONLY

  static async vodafoneTurkeyGetPackages({page, pageSize} = {}) {
    const response = await ApiService.axi.get(vodafoneTurkeyPackagesPath, {
      params: {
        ...createOffsetLimitParams({page, pageSize}),
        total_count: true,
      },
    });

    const totalCount = getTotalCountFrom(response);
    const data = response.data?._embedded?.subscriptions;

    // Provide expected id attributes for the object (necessary for tables)
    data.forEach(item => {
      // eslint-disable-next-line no-param-reassign
      item.id = item.subscription_id;
    });

    return {
      count: totalCount,
      data,
    };
  }

  static async vodafoneTurkeyGetPackage(id) {
    const response = await ApiService.axi.get(
      `${vodafoneTurkeyPackagesPath}/${id}`,
    );

    const pkg = response.data;
    pkg.id = pkg.subscription_id;

    return pkg;
  }

  static async vodafoneTurkeyUpdatePackage(id, values) {
    const response = await ApiService.axi.patch(
      `${vodafoneTurkeyPackagesPath}/${id}`,
      values,
    );

    return response.data;
  }

  static async vodafoneTurkeyDeletePackage(id) {
    return ApiService.deleteEntity(`${vodafoneTurkeyPackagesPath}/${id}`);
  }

  // Vodafone Turkey packages
  //= ================================================================

  /// ////////////////////////////////////////////////////////////////
  // Promocodes

  static async getProvisionerServices() {
    const response = await ApiService.axi.get(provisionerServicesPath, {
      params: {
        total_count: true,
        limit: 10000,
      },
    });

    return response.data?._embedded?.services;
  }

  // This methods returns promocodes with services activated(!) by those promocodes.
  // Each promo has additional "service" field.
  static async getPromocodes({
    page,
    pageSize,
    provisionerServices,
    codeSearchString,
    hiddenFilter,
    orderBy,
    orderDirection,
    group_id,
  } = {}) {
    const response = await ApiService.axi.get(promocodesPath, {
      params: {
        ...createOffsetLimitParams({page, pageSize}),
        total_count: true,
        code__icontains: codeSearchString,
        is_hidden: promocodeHiddenFilterUiValue2BackendValue[hiddenFilter],
        order_by: createOrderByMinus(orderBy, orderDirection),
        group_id,
      },
    });

    const totalCount = getTotalCountFrom(response);
    const promocodesWoutServices = response.data?._embedded?.promocodes;

    const promocodesWithServices = provisionerServices
      ? promocodesWoutServices.map(promo => {
          const promoSrv = provisionerServices.find(srv =>
            srv?.activation_events?.find(
              evt =>
                evt.type === 'promo_code_activated' && evt.value === promo.id,
            ),
          );
          // eslint-disable-next-line no-param-reassign
          promo.service = promoSrv;
          return promo;
        })
      : promocodesWoutServices;

    return {
      count: totalCount,
      data: promocodesWithServices,
    };
  }

  static async getPromocode(id) {
    const response = await ApiService.axi.get(`${promocodesPath}/${id}`);

    return response.data;
  }

  static async getPromocodeGroups() {
    const response = await ApiService.axi.get(`${promocodesGroupsPath}`);

    return response.data;
  }

  static async getPromocodeGroup(id) {
    const response = await ApiService.axi.get(`${promocodesGroupsPath}/${id}`);

    return response.data;
  }

  static async createProvisionerService({
    promocodeId,
    name,
    description,
    activationLimit,
    storageType,
    size,
    periodType,
    duration,
  }) {
    const response = await ApiService.axi.post(provisionerServicesPath, {
      name,
      description,
      storage_type: storageType,
      size,
      period_type: periodType,
      duration,
      activation_limit: activationLimit,
      activation_events: [
        {
          type: 'promo_code_activated',
          value: promocodeId,
        },
      ],
    });

    return response.data;
  }

  static async updateProvisionerService({
    id,
    name,
    description,
    activationLimit,
    storageType,
    size,
    periodType,
    duration,
    startDt,
    endDt,
  }) {
    const response = await ApiService.axi.patch(
      `${provisionerServicesPath}/${id}`,
      {
        name,
        description,
        activation_limit: activationLimit || 0,
        storage_type: storageType,
        size,
        period_type: periodType,
        duration: duration || 0,
        available_start_time: startDt ? startDt.valueOf() : 0,
        available_end_time: endDt ? endDt.valueOf() : 0,
      },
    );

    return response.data;
  }

  static async createPromocode({
    code,
    name,
    description,
    expiredDt,
    activationLimit = 0,
    hidden,
    eventName,
    storageType,
    size,
    periodType,
    duration = 0,
    group_id,
  }) {
    const createPromocodeResponse = await ApiService.axi.post(promocodesPath, {
      code,
      name,
      description,
      expired: expiredDt ? expiredDt.valueOf() : 0,
      activation_limit: activationLimit,
      is_hidden: hidden,
      event_name: eventName,
      group_id,
    });

    const promocode = createPromocodeResponse.data;

    await ApiService.createProvisionerService({
      promocodeId: promocode?.id,
      name: createProvisionerServiceNameFrom(name),
      description,
      activationLimit,
      storageType,
      size,
      periodType,
      duration,
    });

    return promocode;
  }

  static async updatePromocode({
    id,
    name,
    description,
    expiredDt,
    activationLimit,
    hidden,
    eventName,
    group_id,
  }) {
    const updatePromocodeResponse = await ApiService.axi.patch(
      `${promocodesPath}/${id}`,
      {
        name,
        description,
        expired: expiredDt ? expiredDt.valueOf() : 0,
        activation_limit: activationLimit || 0,
        is_hidden: hidden,
        event_name: eventName,
        group_id,
      },
    );

    return updatePromocodeResponse.data;
  }

  static async updatePromocodeGroup({id, name, description}) {
    const updatePromocodeGroupResponse = await ApiService.axi.patch(
      `${promocodesGroupsPath}/${id}`,
      {
        name,
        description,
      },
    );

    return updatePromocodeGroupResponse.data;
  }

  static async createPromocodeGroup({name, description}) {
    const createPromocodeGroupResponse = await ApiService.axi.post(
      `${promocodesGroupsPath}`,
      {
        name,
        description,
      },
    );

    return createPromocodeGroupResponse.data;
  }

  // TODO remove if not used
  static async updatePromocodeAndService({
    id,
    serviceId,
    name,
    description,
    expiredDt,
    activationLimit,
    hidden,
    eventName,
    storageType,
    size,
    periodType,
    duration,
    group_id,
  }) {
    const updatePromocodeResponse = await ApiService.axi.patch(
      `${promocodesPath}/${id}`,
      {
        name,
        description,
        expired: expiredDt ? expiredDt.valueOf() : 0,
        activation_limit: activationLimit || 0,
        is_hidden: hidden,
        event_name: eventName,
        group_id,
      },
    );

    await ApiService.updateProvisionerService({
      id: serviceId,
      name: createProvisionerServiceNameFrom(name),
      description,
      activationLimit,
      storageType,
      size,
      periodType,
      duration,
    });

    return updatePromocodeResponse.data;
  }

  static deletePromocode(id) {
    return ApiService.deleteEntity(`${promocodesPath}/${id}`);
  }

  static getPromocodeRelatedServices({
    promocodeId,
    promocodeEventName,
    services,
  }) {
    return services.filter(srv => {
      const srvActivationEvents = srv.activation_events;
      if (srvActivationEvents) {
        // Try find event related to the passed promo
        const promoRelatedEvent = srvActivationEvents.find(
          evt =>
            (evt.type === 'promo_code_activated' &&
              evt.value === promocodeId) ||
            (promocodeEventName &&
              evt.type === 'custom_event' &&
              evt.value === promocodeEventName),
        );
        if (promoRelatedEvent) {
          return true;
        }
      }

      return false;
    });
  }

  // code param - promocode's code
  static async getPromocodeUsers({code, page, pageSize} = {}) {
    const response = await ApiService.axi.get(
      `${PROVISIONER_ADDR}/provisioning-api/0/admin_resources/users_promocodes`,
      {
        params: {
          ...createOffsetLimitParams({page, pageSize}),
          total_count: true,
          promo_code: code,
        },
      },
    );

    const totalCount = getTotalCountFrom(response);
    const origUsers = response.data?._embedded?.users_promocodes;

    const userIds = origUsers.map(u => u.user_id);

    const creds = await ApiService.getCredentialsForUsers(userIds);
    const fullUsers = await ApiService.getEntitiesByIds({
      ids: userIds,
      getEntityApiMethod: ApiService.getUser,
      retValOnError: null,
    });

    const updatedUsers = origUsers.map((user, userInd) => {
      const updatedUser = {...user};
      updatedUser.credentials = creds[userInd];
      updatedUser.tenantName = fullUsers[userInd]?.tenantName;
      return updatedUser;
    });

    return {
      count: totalCount,
      data: updatedUsers,
    };
  }

  // Promocodes
  /// ////////////////////////////////////////////////////////////////

  //* *****************************************************************
  // Cloudmax packages
  // CLOUDMAX ONLY

  static async cloudmaxGetPackages({page, pageSize} = {}) {
    const response = await ApiService.axi.get(cloudmaxPackagesPath, {
      params: {
        ...createOffsetLimitParams({page, pageSize}),
        total_count: true,
      },
    });

    const totalCount = getTotalCountFrom(response);
    const data = response.data?._embedded?.packages;

    return {
      count: totalCount,
      data,
    };
  }

  static async cloudmaxGetPackage(id) {
    const response = await ApiService.axi.get(`${cloudmaxPackagesPath}/${id}`);

    return response.data;
  }

  static async cloudmaxUpdatePackage(id, values) {
    const response = await ApiService.axi.patch(
      `${cloudmaxPackagesPath}/${id}`,
      values,
    );

    return response.data;
  }

  static async cloudmaxDeletePackage(id) {
    return ApiService.deleteEntity(`${cloudmaxPackagesPath}/${id}`);
  }

  // Cloudmax packages
  //* *****************************************************************

  // Billing
  //= ===========================================================================

  //---------------------------------------------------------------------------
  // CSV Reports

  static async getReports({page, pageSize} = {}) {
    const response = await ApiService.axi.get(
      `${PROVISIONER_ADDR}/provisioning-api/1/reports/orders`,
      {
        params: {
          ...createOffsetLimitParams({page, pageSize}),
          total_count: true,
        },
      },
    );

    const totalCount = getTotalCountFrom(response);

    return {
      count: totalCount,
      data: response.data._embedded?.reports,
    };
  }

  static async createReports(data) {
    const response = await ApiService.axi.post(
      `${PROVISIONER_ADDR}/provisioning-api/1/reports/orders`,
      data,
    );

    return response.data;
  }
}

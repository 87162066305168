export const TAB_ROUTE_PARAM = ':tab?';

const createCardRoute = (tableRoute, withTabParam = false) => {
  const cardRoute = `${tableRoute}/:id`;

  if (!withTabParam) {
    return cardRoute;
  }

  return `${cardRoute}/${TAB_ROUTE_PARAM}`;
};

// Errors
export const ROUTE__ERROR__404 = '/404';
export const ROUTE__ERROR__403 = '/403';

export const ROUTE__LOGIN = '/login';

export const ROUTE__DASHBOARD = '/app';
export const ROUTE__DASHBOARD__MANAGEMENT = `${ROUTE__DASHBOARD}/management`;
export const ROUTE__DASHBOARD__ADMIN = `${ROUTE__DASHBOARD}/admin`;
export const ROUTE__DASHBOARD__MESSAGES = `${ROUTE__DASHBOARD}/messages`;
export const ROUTE__DASHBOARD__ANALYTICS = `${ROUTE__DASHBOARD}/analytics`;
export const ROUTE__DASHBOARD__BILLING = `${ROUTE__DASHBOARD}/billing`;

// Key-Value
export const ROUTE__KEY_VALUE__LIST = `${ROUTE__DASHBOARD__MANAGEMENT}/key-value`;
export const ROUTE__KEY_VALUE__CREATE = `${ROUTE__KEY_VALUE__LIST}/create`;
export const ROUTE__KEY_VALUE__EDIT = `${ROUTE__KEY_VALUE__LIST}/:id/edit`;

// SSO clients
export const ROUTE__SSO_CLIENT__LIST = `${ROUTE__DASHBOARD__MANAGEMENT}/sso-clients`;
export const ROUTE__SSO_CLIENT__CREATE = `${ROUTE__SSO_CLIENT__LIST}/create`;
export const ROUTE__SSO_CLIENT__EDIT = `${ROUTE__SSO_CLIENT__LIST}/:id/edit`;

// Feedbacks
export const ROUTE__FEEDBACKS__LIST = `${ROUTE__DASHBOARD__MANAGEMENT}/feedbacks`;
export const ROUTE__FEEDBACK__CARD = `${ROUTE__FEEDBACKS__LIST}/:id`;

// Users
export const ROUTE__USERS = `${ROUTE__DASHBOARD__MANAGEMENT}/users`;
export const ROUTE__USER = createCardRoute(ROUTE__USERS);
export const ROUTE__USER__CREATE = `${ROUTE__USERS}/create`;
export const ROUTE__USER__EDIT = `${ROUTE__USER}/edit`;
export const ROUTE__USER__PUSH_SUBSCRIPTION = `${ROUTE__USER}/push-subscription`;
export const ROUTE__USER__PHOTO_OPERATION = `${ROUTE__USER}/photo-operation/:operationId`;
export const ROUTE__USERS_CSV__TABLE = `${ROUTE__DASHBOARD__MANAGEMENT}/reports`;
export const ROUTE__USERS_CSV__TABLE_CREATE = `${ROUTE__DASHBOARD__MANAGEMENT}/reports/create`;
export const ROUTE__USERS__ADMINS_TABLE = `${ROUTE__DASHBOARD__ADMIN}/admins`;
export const ROUTE__USERS__ADMINS_EDIT = `${ROUTE__USERS__ADMINS_TABLE}/:id/edit`;

// Client versions
export const ROUTE__CLIENT_VERSIONS__TABLE = `${ROUTE__DASHBOARD__MANAGEMENT}/client-versions`;
export const ROUTE__CLIENT_VERSION__CREATE = `${ROUTE__CLIENT_VERSIONS__TABLE}/create`;
export const ROUTE__CLIENT_VERSION__EDIT = `${ROUTE__CLIENT_VERSIONS__TABLE}/:id/edit`;

// Offers
export const ROUTE__OFFERS__TABLE = `${ROUTE__DASHBOARD__MANAGEMENT}/offers`;
export const ROUTE__OFFER__CREATE = `${ROUTE__OFFERS__TABLE}/create`;
export const ROUTE__OFFER__EDIT = `${ROUTE__OFFERS__TABLE}/:id/edit`;
export const ROUTE__OFFER__RAW_VIEW = `${ROUTE__OFFERS__TABLE}/:id`;

// Tenants
export const ROUTE__TENANTS__TABLE = `${ROUTE__DASHBOARD__ADMIN}/tenants`;
export const ROUTE__TENANT__CREATE = `${ROUTE__TENANTS__TABLE}/create`;
export const ROUTE__TENANT__EDIT = `${ROUTE__TENANTS__TABLE}/edit`;

// Buckets
export const ROUTE__BUCKETS__TABLE = `${ROUTE__DASHBOARD__ADMIN}/buckets`;
export const ROUTE__BUCKET__CREATE = `${ROUTE__BUCKETS__TABLE}/create`;
export const ROUTE__BUCKET__EDIT = `${ROUTE__BUCKETS__TABLE}/:id/edit`;

// Messages
export const ROUTE__MESSAGES__CREATE = `${ROUTE__DASHBOARD__MESSAGES}/create`;
export const ROUTE__MESSAGES__VIEW = `${ROUTE__DASHBOARD__MESSAGES}/view/:tab`;
export const VIEW_MESSAGES_TAB__MESSAGES = 'messages';
export const VIEW_MESSAGES_TAB__MESSAGE_DISTRIBUTIONS = 'message-distributions';
export const VIEW_MESSAGES_TAB__PUSH_TOPIC_TASKS = 'push-topic-tasks';
export const VIEW_MESSAGES_TAB__PUSH_TOPICS = 'push-topics';
export const ROUTE__MESSAGES__VIEW__MESSAGES = ROUTE__MESSAGES__VIEW.replace(
  ':tab',
  VIEW_MESSAGES_TAB__MESSAGES,
);
export const ROUTE__MESSAGES__VIEW__MESSAGE_DISTRIBUTIONS = ROUTE__MESSAGES__VIEW.replace(
  ':tab',
  VIEW_MESSAGES_TAB__MESSAGE_DISTRIBUTIONS,
);
export const ROUTE__MESSAGES__VIEW__PUSH_TOPIC_TASKS = ROUTE__MESSAGES__VIEW.replace(
  ':tab',
  VIEW_MESSAGES_TAB__PUSH_TOPIC_TASKS,
);
export const ROUTE__MESSAGES__VIEW__PUSH_TOPICS = ROUTE__MESSAGES__VIEW.replace(
  ':tab',
  VIEW_MESSAGES_TAB__PUSH_TOPICS,
);
export const ROUTE__USER_MESSAGE = createCardRoute(
  ROUTE__MESSAGES__VIEW__MESSAGES,
);
export const ROUTE__MESSAGE_DISTRIBUTION = createCardRoute(
  ROUTE__MESSAGES__VIEW__MESSAGE_DISTRIBUTIONS,
);
export const ROUTE__PUSH_TOPIC_TASK = createCardRoute(
  ROUTE__MESSAGES__VIEW__PUSH_TOPIC_TASKS,
);

// Analytics
export const ROUTE__METRICS = `${ROUTE__DASHBOARD__ANALYTICS}/metrics`;
export const ROUTE__STORAGE_ANALYTICS = `${ROUTE__DASHBOARD__ANALYTICS}/storage`;
export const ROUTE__REPORTS = `${ROUTE__DASHBOARD__ANALYTICS}/reports`;
export const ROUTE__REPORTS_CREATE = `${ROUTE__DASHBOARD__ANALYTICS}/reports/create`;

// Notification templates
export const ROUTE__NOTIF_TEMPLATES__TABLE = `${ROUTE__DASHBOARD__MESSAGES}/templates`;
export const ROUTE__NOTIF_TEMPLATE__CREATE = `${ROUTE__NOTIF_TEMPLATES__TABLE}/create`;
export const ROUTE__NOTIF_TEMPLATE__EDIT = `${ROUTE__NOTIF_TEMPLATES__TABLE}/edit`;

// Billing
// Packages
export const ROUTE__PACKAGES_TABLE = `${ROUTE__DASHBOARD__BILLING}/packages`;
export const ROUTE__PACKAGE_CARD = createCardRoute(ROUTE__PACKAGES_TABLE);
// Promocodes
export const ROUTE__PROMOCODES_TABLE = `${ROUTE__DASHBOARD__BILLING}/promocodes`;
export const ROUTE__PROMOCODE__CREATE = `${ROUTE__PROMOCODES_TABLE}/create`;
export const ROUTE__PROMOCODE__GROUPS = `${ROUTE__DASHBOARD__BILLING}/promo_code_groups`;
export const ROUTE__PROMOCODE__CARD = createCardRoute(ROUTE__PROMOCODES_TABLE);
export const ROUTE__PROMOCODE__GROUP = createCardRoute(
  ROUTE__PROMOCODE__GROUPS,
);

// Test snack
export const ROUTE__TEST_SNACK = `${ROUTE__DASHBOARD}/test-snack`;

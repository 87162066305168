/* eslint-disable no-use-before-define */
import React, {memo, useMemo} from 'react';
import PropTypes from 'prop-types';
import {useLocation, matchPath} from 'react-router-dom';
import {Box, Drawer, List, ListSubheader, makeStyles} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useTranslation} from 'react-i18next';

import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import createMenuConfig from './createMenuConfig';
import {HEADER_HEIGHT, MINIBAR_WIDTH, SIDEBAR_WIDTH} from '../constants';

function renderNavItems({items, pathname, depth = 0, isMiniMode}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({acc, item, pathname, depth, isMiniMode}),
        [],
      )}
    </List>
  );
}

function reduceChildRoutes({acc, pathname, item, depth, isMiniMode}) {
  const key = item.titleKey + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        titleKey={item.titleKey}
        isMiniMode={isMiniMode}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>,
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        titleKey={item.titleKey}
        isMiniMode={isMiniMode}
      />,
    );
  }

  return acc;
}

const sidebarHeight = `calc(100% - ${HEADER_HEIGHT}px)`;
const sidebarStyles = {
  top: HEADER_HEIGHT,
  height: sidebarHeight,
};

const useStyles = makeStyles(theme => ({
  sidebarDrawer: {
    width: SIDEBAR_WIDTH,
    ...sidebarStyles,
  },
  miniSidebarDrawer: {
    width: MINIBAR_WIDTH,
    ...sidebarStyles,
  },
  subheader: {
    color: theme.palette.text.primary,
    // made it shorted (otherwise a vertical scrollbar appears in the sidebar)
    lineHeight: '2.5rem',
  },
}));

const NavBar = ({onSidebarClose, isSidebarOpened}) => {
  const classes = useStyles();
  const location = useLocation();
  const [t] = useTranslation();
  const {user} = useAuth();

  const sections = useMemo(() => createMenuConfig(user), [user]);

  const createMenu = ({isMiniMode} = {}) => (
    <Box p={isMiniMode ? 1 : 2}>
      {sections.map(section => (
        <List
          key={section.subheaderKey}
          subheader={
            isMiniMode ? undefined : (
              <ListSubheader
                disableGutters
                disableSticky
                className={classes.subheader}
              >
                {t(section.subheaderKey)}
              </ListSubheader>
            )
          }
        >
          {renderNavItems({
            items: section.items,
            pathname: location.pathname,
            isMiniMode,
          })}
        </List>
      ))}
    </Box>
  );

  const createSideBar = () => {
    return (
      <PerfectScrollbar>
        <Box height="100%" display="flex" flexDirection="column">
          {createMenu()}
        </Box>
      </PerfectScrollbar>
    );
  };

  const createMiniSideBar = () => {
    return (
      <PerfectScrollbar>
        <Box height="100%" display="flex" flexDirection="column">
          {createMenu({isMiniMode: true})}
        </Box>
      </PerfectScrollbar>
    );
  };

  return (
    <>
      {isSidebarOpened && (
        // Full Sidebar
        <Drawer
          anchor="left"
          classes={{paper: classes.sidebarDrawer}}
          onClose={onSidebarClose}
          open
          variant="persistent"
        >
          {createSideBar()}
        </Drawer>
      )}

      {!isSidebarOpened && (
        // Minibar
        <Drawer
          anchor="left"
          classes={{paper: classes.miniSidebarDrawer}}
          open
          variant="persistent"
        >
          {createMiniSideBar()}
        </Drawer>
      )}
    </>
  );
};

NavBar.propTypes = {
  onSidebarClose: PropTypes.func.isRequired,
  isSidebarOpened: PropTypes.bool.isRequired,
};

export default memo(NavBar);

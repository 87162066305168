import {createStyles, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.dangerAction': {
        color: `${theme.palette.error.main} !important`,
        '&:hover': {
          color: `${theme.palette.error.dark} !important`,
        },
      },
      '.actionDisabled': {
        color: `${theme.palette.action.disabled} !important`,
      },
      // Applied to any page like Key-Value or SSO clients.
      '.standardPage': {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      /// //////////////////////////////////////////////////
      // Applied to any table like Key-Value or SSO clients.
      // The table has search input, fixed header and occupies whole page height.
      '.standardTableContainer': {
        minWidth: 700,
        // 64px - header
        // 24 * 2 - page content padding
        // 76 - breadcrumbs
        // 60 + 2 * 16 - search field + its padding
        maxHeight: 'calc(100vh - 64px - 24px * 2 - 76px - 60px - 16px * 2)',
      },
      '.standardTable': {
        '& td': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      /// //////////////////////////////////////////////////
      '.whiteSpacePre': {
        whiteSpace: 'pre',
      },
      '.whiteSpacePreWrap': {
        whiteSpace: 'pre-wrap',
      },
      '.checkboxDeletedUser': {
        marginLeft: 20,
      },
    },
  }),
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;

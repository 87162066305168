import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import Page from 'src/components/Page';
// Defined during the build process.
// eslint-disable-next-line import/no-unresolved
import errorPageImg from 'COMMON_IMAGES/sad_cloud.png';
import Text from 'src/components/DisplayStrings/Text';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80,
  },
  image: {
    maxWidth: '100%',
    width: 300,
    height: 'auto',
  },
}));

const FrontendError = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [t] = useTranslation();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Page className={classes.root} titleKey="notifText__somethingWentWrong">
      <Container maxWidth="lg">
        <Typography
          align="center"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          <Text transKey="notifText__somethingWentWrong" />
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <img
            alt="Under development"
            className={classes.image}
            src={errorPageImg}
          />
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          <Button
            color="secondary"
            component={RouterLink}
            to="/"
            variant="outlined"
          >
            {t('buttonText__backToHome', 'Back to home')}
          </Button>
        </Box>
      </Container>
    </Page>
  );
};

export default FrontendError;

// IMPORTANT
// This code must correspond to:
// * ssoClient prop type
// * decode method
export default function decode(ssoClient) {
  const {id, frontendUrl, callbackUrl, description, isDefault} = ssoClient;

  return {
    client_id: id,
    sso_frontend_url: frontendUrl,
    callback_url: callbackUrl,
    description,
    default: isDefault,
  };
}

import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import useAuth from 'src/hooks/useAuth';
import {showErrorNotif} from 'src/utils/errors';

const useStyles = makeStyles(theme => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const ref = useRef(null);
  const [t] = useTranslation();
  const {user, logout} = useAuth();
  const {enqueueSnackbar} = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push('/');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      showErrorNotif({enqueueSnackbar, msg: 'Unable to logout'});
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={user.avatar} />
        <Hidden smDown>
          <Typography variant="h6" color="textPrimary">
            {user.name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{className: classes.popover}}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>
          {t('buttonText__logout', 'Logout')}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Account;

import moment from 'moment';
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import {useTranslation} from 'react-i18next';

import {getLangValue} from 'src/utils/i18n';

const DateTimePickersProvider = ({children, ...restProps}) => {
  const [, i18n] = useTranslation();

  const currentLang = getLangValue(i18n.language);

  useEffect(() => {
    if (currentLang !== 'en') {
      // Here dynamic require is definitely needed
      // eslint-disable-next-line import/no-dynamic-require,global-require
      require(`moment/locale/${currentLang}`);
    }
    moment.locale(currentLang);
  }, [currentLang]);

  return (
    <MuiPickersUtilsProvider
      utils={MomentUtils}
      locale={currentLang}
      {...restProps}
    >
      {children}
    </MuiPickersUtilsProvider>
  );
};

DateTimePickersProvider.propTypes = {
  children: PropTypes.element,
};

export default DateTimePickersProvider;

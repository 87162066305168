import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from 'src/hooks/useAuth';
import {ROUTE__LOGIN} from 'src/constants/routes';
import {routerBasename as publicPath} from 'src/utils/router';

const AuthGuard = ({children}) => {
  const {isAuthenticated} = useAuth();

  // window.location can contain publicPath.
  // But for routing, we have to use routes wout leading public path.
  let pathname = window.location.pathname;
  if (publicPath !== '/' && pathname.startsWith(publicPath)) {
    pathname = pathname.replace(publicPath, '');
  }

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: ROUTE__LOGIN,
          state: {
            // this URL will be used by the login page for redirection after successful login
            afterLoginRedirectTo: pathname + window.location.search,
          },
        }}
      />
    );
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;

import React, {useState, useRef} from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Typography,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import {Settings as SettingsIcon} from 'react-feather';
import {useTranslation} from 'react-i18next';

import useSettings from 'src/hooks/useSettings';
import {themeName2labelKey, THEMES} from 'src/constants';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5,
  },
  popover: {
    width: 320,
    padding: theme.spacing(2),
  },
}));

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const [t] = useTranslation();
  const {settings, saveSettings} = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme,
  });

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value,
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen} ref={ref}>
        <SvgIcon fontSize="small">
          <SettingsIcon />
        </SvgIcon>
      </IconButton>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        classes={{paper: classes.popover}}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          {t('topBar__settings__title', 'Settings')}
        </Typography>
        <Box mt={2} px={1}>
          <FormControlLabel
            control={
              <Switch
                checked={values.direction === 'rtl'}
                edge="start"
                name="direction"
                onChange={event =>
                  handleChange(
                    'direction',
                    event.target.checked ? 'rtl' : 'ltr',
                  )
                }
              />
            }
            label="RTL"
          />
        </Box>
        <Box mt={2} px={1}>
          <FormControlLabel
            control={
              <Switch
                checked={values.responsiveFontSizes}
                edge="start"
                name="direction"
                onChange={event =>
                  handleChange('responsiveFontSizes', event.target.checked)
                }
              />
            }
            label={t(
              'settings__responsiveFontsSwitch__label',
              'Responsive font sizes',
            )}
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            label={t('settings__themeSelect__label', 'Theme')}
            name="theme"
            onChange={event => handleChange('theme', event.target.value)}
            select
            value={values.theme}
          >
            {Object.keys(THEMES).map(theme => (
              <MenuItem key={theme} value={theme}>
                {t(themeName2labelKey[theme])}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            {t('settings__saveButton__text', 'Save Settings')}
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;

import {TAB_ROUTE_PARAM} from 'src/constants/routes';

// Defined during the building from the environment.
// eslint-disable-next-line no-undef
let publicPath = `${__publicPath__}`;
if (publicPath !== '/') {
  publicPath = publicPath.replace(/\/$/, '');
}

export const routerBasename = publicPath;

export const createCardRoute = (cardRoute, id = '', tabName = '') =>
  cardRoute.replace(':id', id).replace(TAB_ROUTE_PARAM, tabName);

export const goBack = (history, route2GoIfCantGoBack) => {
  if (
    route2GoIfCantGoBack &&
    history.action === 'POP' &&
    history.length === 1
  ) {
    history.push(route2GoIfCantGoBack);
  } else {
    history.goBack();
  }
};

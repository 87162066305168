import React from 'react';
import {Select, MenuItem} from '@material-ui/core';
import {useTranslation} from 'react-i18next';

import reseller from 'src/reseller';
import {getLangValue} from 'src/utils/i18n';

const supportedLangs = reseller.supportedLanguages;

const CurrentLangSelect = props => {
  const [, i18n] = useTranslation();

  const currentLang = getLangValue(i18n.language);

  const handleCurrentLangChange = ({target: {value: locale}}) => {
    if (locale !== currentLang) {
      i18n.changeLanguage(locale);
    }
  };

  return (
    <Select value={currentLang} onChange={handleCurrentLangChange} {...props}>
      {supportedLangs.map(lang => (
        <MenuItem key={lang} value={lang}>
          {lang.toUpperCase()}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CurrentLangSelect;

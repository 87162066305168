export function openNewTab(url, isSafeUrl = false) {
  const win = window.open(
    url,
    '_blank',
    isSafeUrl ? undefined : 'noopener,noreferrer',
  );

  if (win) {
    win.focus();
  }
}

export const MAX_CONCURRENT_DOWNLOADS = 6;

export function downloadFileByUrl({url, fileName, isTargetBlank = true}) {
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.href = url;
  a.setAttribute('download', fileName);
  a.target = isTargetBlank ? '_blank' : '';
  a.click();
  document.body.removeChild(a);
}

import React, {forwardRef} from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const Page = forwardRef(({children, title, titleKey, ...rest}, ref) => {
  const [t] = useTranslation();

  const getTitle = () => {
    if (title) {
      return title;
    }

    return titleKey ? t(titleKey) : '';
  };

  return (
    <div ref={ref} {...rest}>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      {children}
    </div>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titleKey: PropTypes.string,
};

export default Page;

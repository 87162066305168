import {memo, useMemo} from 'react';

import useAuth from 'src/hooks/useAuth';
import renderRoutes from './renderRoutes';
import createRoutesConfig from './createRoutesConfig';

const Routes = () => {
  const {user} = useAuth();

  const routes = useMemo(() => createRoutesConfig(user), [user]);

  return renderRoutes(routes);
};

export default memo(Routes);

import React, {createContext, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';

import SplashScreen from 'src/components/SplashScreen';
import ApiService from 'src/services/ApiService';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const setSession = ({token, user} = {}) => {
  if (token) {
    localStorage.setItem('accessToken', token);
    localStorage.setItem('user', JSON.stringify(user));
    ApiService.setAccessToken(token);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('user');
    ApiService.clearAccessToken();
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const {isAuthenticated, user} = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const {user} = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case 'REGISTER': {
      const {user} = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return {...state};
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'simple',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (email, password) => {
    const data = await ApiService.login(email, password);
    const {token, user} = data;

    setSession({token, user});
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const handleUnauthorized = () => {
    setSession();
    dispatch({type: 'LOGOUT'});
  };

  const logout = async () => {
    try {
      await ApiService.logout();
    } finally {
      handleUnauthorized();
    }
  };

  useEffect(() => {
    const initialise = async () => {
      ApiService.init(handleUnauthorized);

      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken) {
          ApiService.setAccessToken(accessToken);

          // Request current user info - check whether auth-ed or not
          const user = await ApiService.getUserAccount();

          setSession({token: accessToken, user});

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        /* eslint-disable-next-line no-console */
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();

    // Call ApiService.uninit on unmount
    return ApiService.uninit;
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'simple',
        login,
        logout,
        register: () => Promise.resolve(),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import {SettingsProvider} from 'src/contexts/SettingsContext';
import App from 'src/App';
import {initConfigs} from './services/configsService';

// access global vars from build-constants.js
/* eslint-disable no-undef */
const isProduction = __prod__;
const version = `${__version__}`;
// const latestCommitInfo = `${__latestCommitInfo__}`;
const dsn = `${__sentryDsn__}`;
export const defaultID = `${__defauldID__}`;
/* eslint-enable no-undef */

if (isProduction) {
  /* eslint-disable no-console */
  console.log(`admin-ui version: ${version}`);
  // console.log('latest commit: ', latestCommitInfo);
  /* eslint-enable no-console */

  if (dsn) {
    Sentry.init({
      dsn,
      release: version,
    });
  }
}

initConfigs().then();

ReactDOM.render(
  <SettingsProvider>
    <App />
  </SettingsProvider>,
  document.getElementById('root'),
);

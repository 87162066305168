import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {AppBar, Box, Toolbar, makeStyles} from '@material-ui/core';
import {useIsFetching} from 'react-query';

import {THEMES} from 'src/constants';
import CurrentLangSelect from 'src/components/Selects/CurrentLang';
import CircularLoadingIndicator from 'src/components/CircularLoadingIndicator';
import Logo from 'src/components/Logo';
import Account from './Account';
import Settings from './Settings';
import {HEADER_HEIGHT, LOGO_HEIGHT_IN_HEADER} from '../constants';

const useStyles = makeStyles(theme => ({
  root: {
    // The following z-index is necessary for displaying the top-bar above the sidebar.
    // In that case the top-bar's box-shadow is displayed and
    // provides a separation between the top-bar and sidebar.
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          backgroundColor: '#fff',
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {}),
  },
  toolbar: {
    minHeight: HEADER_HEIGHT,
    paddingLeft: theme.spacing(1),
  },
  logo: {
    height: LOGO_HEIGHT_IN_HEADER,
    marginLeft: theme.spacing(1),
  },
}));

const TopBar = ({className, ...rest}) => {
  const classes = useStyles();
  const isFetching = useIsFetching();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Box ml={2} flexGrow={1} />
        {/*
         isFetching is a number. So, if it's 0, then "0" will be displayed.
         To prevent this, cast to bool.
        */}
        {!!isFetching && (
          <Box mr={2}>
            <CircularLoadingIndicator size={24} />
          </Box>
        )}
        <Box mr={2}>
          <CurrentLangSelect />
        </Box>
        <Box mr={2}>
          <Settings />
        </Box>
        <Account />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;

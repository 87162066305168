export const getArrayItemIf = (item, condition) => {
  const conditionResult =
    typeof condition === 'function' ? condition() : !!condition;
  if (conditionResult) {
    return typeof item === 'function' ? [item()] : [item];
  }

  return [];
};

// Changes item's position in the specified array.
// Returns new array.
export const moveItem = (arr, oldPos, newPos) => {
  const result = [...arr];
  const [removed] = result.splice(oldPos, 1);
  result.splice(newPos, 0, removed);
  return result;
};

// This function mutates array.
// orderArray MUST be an array of primitive values
export function sortArrayByAnotherArray(
  array2sort,
  orderArray,
  getItemValue = item => item,
) {
  return array2sort.sort(
    (a, b) =>
      orderArray.indexOf(getItemValue(a)) - orderArray.indexOf(getItemValue(b)),
  );
}

import React from 'react';
import {CircularProgress, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    padding: theme.spacing(3),
    zIndex: 2000,
  },
}));

// Occupies the whole viewport.
// Used for waiting when major resources (js) loaded.
const SplashScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size="5rem" />
    </div>
  );
};

export default SplashScreen;

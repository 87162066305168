import React from 'react';
import PropTypes from 'prop-types';
import {Box, CircularProgress} from '@material-ui/core';

const CircularLoadingIndicator = ({
  containerWidth,
  containerHeight,
  ...restProps
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={containerWidth}
      height={containerHeight}
    >
      <CircularProgress color="primary" {...restProps} />
    </Box>
  );
};

CircularLoadingIndicator.propTypes = {
  containerWidth: PropTypes.number,
  containerHeight: PropTypes.number,
};

export default CircularLoadingIndicator;

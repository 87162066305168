const DEFAULT_ADDITIONAL_CONFIG_PATH = '/assets/configs/additional.config.json';

export async function initConfigs(url = DEFAULT_ADDITIONAL_CONFIG_PATH) {
  try {
    const response = await fetch(url);
    const rsp = await response.json();
    window.localStorage.setItem('configs', JSON.stringify(rsp));
    return await rsp;
  } catch (e) {
    return {};
  }
}

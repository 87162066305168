export const ROLE__USER = 'user';
export const ROLE__ADMIN = 'admin';
export const ROLE__ADMIN__READ_ONLY = 'admin_readonly';
export const ROLE__TENANT_ADMIN = 'tenant_admin';
export const ROLE__TENANT_ADMIN__READ_ONLY = 'tenant_admin_readonly';
export const ROLE__SUPER_ADMIN = 'super_admin';
export const ROLE__APP_VERSIONS_EDIT = 'apps_versions_edit';
export const ROLE__FEEDBACKS_EDIT = 'feedbacks_edit';
export const ROLE__FEEDBACKS_VIEW = 'feedbacks_view';
export const ROLE__MESSAGES_EDIT = 'messages_edit';
export const ROLE__MESSAGES_VIEW = 'messages_view';
export const ROLE__METRICS_VIEW = 'metrics_view';
export const ROLE__NOTIFICATION_TEMPLATES_EDIT = 'notification_templates_edit';
export const ROLE__NOTIFICATION_TEMPLATES_VIEW = 'notification_templates_view';
export const ROLE__OFFERS_EDIT = 'offers_edit';
export const ROLE__PACKAGES_EDIT = 'packages_edit';
export const ROLE__PACKAGES_VIEW = 'packages_view';
export const ROLE__PROMOCODES_EDIT = 'promocodes_edit';
export const ROLE__PROMOCODES_VIEW = 'promocodes_view';
export const ROLE__REPORTS_EDIT = 'reports_edit';
export const ROLE__REPORTS_VIEW = 'reports_view';
export const ROLE__ROLES_EDIT = 'roles_edit';
export const ROLE__ROLES_VIEW = 'roles_view';
export const ROLE__SSO_EDIT = 'sso_edit';
export const ROLE__SSO_VIEW = 'sso_view';
export const ROLE__TENANTS_EDIT = 'tenants_edit';
export const ROLE__TENANTS_VIEW = 'tenants_view';
export const ROLE__USER_PURCHASING_EDIT = 'user_purchasing_edit';
export const ROLE__USER_ROLES_EDIT = 'user_roles_edit';
export const ROLE__USERS_EDIT = 'users_edit';
export const ROLE__USERS_VIEW = 'users_view';

export const role2label = {
  [ROLE__USER]: 'common:role__user',
  [ROLE__ADMIN]: 'common:role__admin',
  [ROLE__ADMIN__READ_ONLY]: 'common:role__admin_read_only',
  [ROLE__TENANT_ADMIN]: 'common:role__tenant_admin',
  [ROLE__TENANT_ADMIN__READ_ONLY]: 'common:role__tenant_admin_read_only',
  [ROLE__SUPER_ADMIN]: 'common:role__super_admin',
  [ROLE__APP_VERSIONS_EDIT]: 'common:role__apps_versions_edit',
  [ROLE__FEEDBACKS_EDIT]: 'common:role__feedbacks_edit',
  [ROLE__FEEDBACKS_VIEW]: 'common:role__feedbacks_view',
  [ROLE__MESSAGES_EDIT]: 'common:role__messages_edit',
  [ROLE__MESSAGES_VIEW]: 'common:role__messages_view',
  [ROLE__METRICS_VIEW]: 'common:role__metrics_view',
  [ROLE__NOTIFICATION_TEMPLATES_EDIT]:
    'common:role__notification_templates_edit',
  [ROLE__NOTIFICATION_TEMPLATES_VIEW]:
    'common:role__notification_templates_view',
  [ROLE__OFFERS_EDIT]: 'common:role__offers_edit',
  [ROLE__PACKAGES_EDIT]: 'common:role__packages_edit',
  [ROLE__PACKAGES_VIEW]: 'common:role__packages_view',
  [ROLE__PROMOCODES_EDIT]: 'common:role__promocodes_edit',
  [ROLE__PROMOCODES_VIEW]: 'common:role__promocodes_view',
  [ROLE__REPORTS_EDIT]: 'common:role__reports_edit',
  [ROLE__REPORTS_VIEW]: 'common:role__reports_view',
  [ROLE__ROLES_EDIT]: 'common:role__roles_edit',
  [ROLE__ROLES_VIEW]: 'common:role__roles_view',
  [ROLE__SSO_EDIT]: 'common:role__sso_edit',
  [ROLE__SSO_VIEW]: 'common:role__sso_view',
  [ROLE__TENANTS_EDIT]: 'common:role__tenants_edit',
  [ROLE__TENANTS_VIEW]: 'common:role__tenants_view',
  [ROLE__USER_PURCHASING_EDIT]: 'common:role__user_purchasing_edit',
  [ROLE__USER_ROLES_EDIT]: 'common:role__user_roles_edit',
  [ROLE__USERS_EDIT]: 'common:role__users_edit',
  [ROLE__USERS_VIEW]: 'common:role__users_view',
};

export const description2label = {
  [ROLE__USER]: 'common:roles_descriptionBase',
  [ROLE__ADMIN]: 'common:roles_descriptionBase',
  [ROLE__ADMIN__READ_ONLY]: 'common:roles_descriptionBase',
  [ROLE__TENANT_ADMIN]: 'common:roles_descriptionBase',
  [ROLE__TENANT_ADMIN__READ_ONLY]: 'common:roles_descriptionBase',
  [ROLE__SUPER_ADMIN]: 'common:roles_descriptionBase',
  [ROLE__APP_VERSIONS_EDIT]: 'common:role__apps_versions_edit_description',
  [ROLE__FEEDBACKS_EDIT]: 'common:role__feedbacks_edit_description',
  [ROLE__FEEDBACKS_VIEW]: 'common:role__feedbacks_view_description',
  [ROLE__MESSAGES_EDIT]: 'common:role__messages_edit_description',
  [ROLE__MESSAGES_VIEW]: 'common:role__messages_view_description',
  [ROLE__METRICS_VIEW]: 'common:role__metrics_view_description',
  [ROLE__NOTIFICATION_TEMPLATES_EDIT]:
    'common:role__notification_templates_edit_description',
  [ROLE__NOTIFICATION_TEMPLATES_VIEW]:
    'common:role__notification_templates_view_description',
  [ROLE__OFFERS_EDIT]: 'common:role__offers_edit_description',
  [ROLE__PACKAGES_EDIT]: 'common:role__packages_edit_description',
  [ROLE__PACKAGES_VIEW]: 'common:role__packages_view_description',
  [ROLE__PROMOCODES_EDIT]: 'common:role__promocodes_edit_description',
  [ROLE__PROMOCODES_VIEW]: 'common:role__promocodes_view_description',
  [ROLE__REPORTS_EDIT]: 'common:role__reports_edit_description',
  [ROLE__REPORTS_VIEW]: 'common:role__reports_view_description',
  [ROLE__ROLES_EDIT]: 'common:role__roles_edit_description',
  [ROLE__ROLES_VIEW]: 'common:role__roles_view_description',
  [ROLE__SSO_EDIT]: 'common:role__sso_edit_description',
  [ROLE__SSO_VIEW]: 'common:role__sso_view_description',
  [ROLE__TENANTS_EDIT]: 'common:role__tenants_edit_description',
  [ROLE__TENANTS_VIEW]: 'common:role__tenants_view_description',
  [ROLE__USER_PURCHASING_EDIT]: 'common:role__user_purchasing_edit_description',
  [ROLE__USER_ROLES_EDIT]: 'common:role__user_roles_edit_description',
  [ROLE__USERS_EDIT]: 'common:role__users_edit_description',
  [ROLE__USERS_VIEW]: 'common:role__users_view_description',
};

export const getRoleLabel = role => {
  if (!role) {
    return '';
  }

  return role2label[role] || role;
};

export const getDescriptionLabel = role => {
  if (!role) {
    return '';
  }

  return description2label[role] || role;
};

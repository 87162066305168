import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {IconButton, makeStyles, SvgIcon, Box} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';

import useSettings from 'src/hooks/useSettings';
import {THEMES} from 'src/constants';
import NavBar from './NavBar';
import TopBar from './TopBar';
import {HEADER_HEIGHT, MINIBAR_WIDTH, SIDEBAR_WIDTH} from './constants';

const sidebarToggleXoffset = 16; // in px, negative (subtract)

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    paddingTop: HEADER_HEIGHT,
    // padding for the minibar
    paddingLeft: MINIBAR_WIDTH,
    '&.isSidebarOpened': {
      // padding for the full sidebar
      paddingLeft: SIDEBAR_WIDTH,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  sidebarToggle: {
    position: 'fixed',
    top: 200,
    left: MINIBAR_WIDTH - sidebarToggleXoffset,
    // Confirmation wrapper has zIndex = 1300.
    // So, this toggle should be beneath the confirmation.
    zIndex: 1299,
    border: `1px solid ${theme.palette.divider}`,
    '&.isSidebarOpened': {
      left: SIDEBAR_WIDTH - sidebarToggleXoffset,
    },
    backgroundColor: theme.name === THEMES.LIGHT ? 'white' : 'grey',
    color: theme.name === THEMES.LIGHT ? 'grey' : 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
  },
}));

const SIDEBAR_SETTING_KEY = 'sidebarOpenedState';
const SIDEBAR_SETTING_VALUE__OPENED = 'opened';
const SIDEBAR_SETTING_VALUE__CLOSED = 'closed';

const DashboardLayout = ({children}) => {
  const {settings} = useSettings();
  const classes = useStyles();
  const [isSidebarOpened, setSidebarOpened] = useState(
    localStorage.getItem(SIDEBAR_SETTING_KEY) === SIDEBAR_SETTING_VALUE__OPENED,
  );

  const toggleSidebar = useCallback(() => {
    const newSidebarOpenState = !isSidebarOpened;
    setSidebarOpened(newSidebarOpenState);

    // setTimeout is necessary because working with localStorage is synchronous.
    // So, don't stop the main thread - store the setting on a next tick.
    setTimeout(
      () =>
        localStorage.setItem(
          SIDEBAR_SETTING_KEY,
          newSidebarOpenState
            ? SIDEBAR_SETTING_VALUE__OPENED
            : SIDEBAR_SETTING_VALUE__CLOSED,
        ),
      0,
    );
  }, [setSidebarOpened, isSidebarOpened]);
  const closeSidebar = useCallback(() => setSidebarOpened(false), [
    setSidebarOpened,
  ]);

  return (
    <Box className={classes.root} component={PerfectScrollbar}>
      <TopBar />
      <NavBar
        onSidebarClose={closeSidebar}
        isSidebarOpened={isSidebarOpened}
        onSidebarToggle={toggleSidebar}
      />
      <div className={clsx(classes.wrapper, {isSidebarOpened})}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>

      <IconButton
        onClick={toggleSidebar}
        className={clsx(classes.sidebarToggle, {isSidebarOpened})}
        size="small"
      >
        <SvgIcon>
          {settings.direction === 'ltr' &&
            (isSidebarOpened ? <ChevronLeftIcon /> : <ChevronRightIcon />)}
          {settings.direction === 'rtl' &&
            (isSidebarOpened ? <ChevronRightIcon /> : <ChevronLeftIcon />)}
        </SvgIcon>
      </IconButton>
    </Box>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;

export const HIDDEN_FILTER__ALL = 'all';
export const HIDDEN_FILTER__ONLY_HIDDEN = 'only hidden';
export const HIDDEN_FILTER__ONLY_PUBLIC = 'only public';

export const hiddenFilterUiValue2BackendValue = {
  [HIDDEN_FILTER__ALL]: undefined,
  [HIDDEN_FILTER__ONLY_HIDDEN]: true,
  [HIDDEN_FILTER__ONLY_PUBLIC]: false,
};

export const hiddenFilterOptions = [
  {
    labelKey: 'billing:promocodesTable__hiddenFilter__option__all',
    value: HIDDEN_FILTER__ALL,
  },
  {
    labelKey: 'billing:promocodesTable__hiddenFilter__option__onlyHidden',
    value: HIDDEN_FILTER__ONLY_HIDDEN,
  },
  {
    labelKey: 'billing:promocodesTable__hiddenFilter__option__onlyPublic',
    value: HIDDEN_FILTER__ONLY_PUBLIC,
  },
];

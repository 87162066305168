module.exports = {
  colors: {
    brandNormal: '#ff3333',
    brandDark: '#d31b0d',
    darkTheme: {
      brandNormal: '#f5979a',
    },
  },
  supportedLanguages: ['en', 'id'],
  offersLang: ['en', 'id'],
};

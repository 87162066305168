import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from 'src/hooks/useAuth';
import {ROLE__ADMIN, ROLE__SUPER_ADMIN} from 'src/utils/users/userAdminRoles';
import {ROUTE__ERROR__404} from 'src/constants/routes';

const AdminGuard = ({children}) => {
  const {
    user: {role},
  } = useAuth();

  if (role !== ROLE__ADMIN && role !== ROLE__SUPER_ADMIN) {
    return <Redirect to={ROUTE__ERROR__404} />;
  }

  return <>{children}</>;
};

AdminGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminGuard;

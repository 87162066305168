import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {CircularProgress, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

import {HEADER_HEIGHT} from 'src/layouts/DashboardLayout/constants';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.dark,
    height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    maxHeight: '100%',
    padding: theme.spacing(3),
  },
}));

// Occupies the major part of the viewport: 100vh - HEADER_HEIGHT
// Used for waiting when particular section loaded (lazy loading, Suspense).
const LoadingScreen = ({className}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <CircularProgress size="5rem" />
    </div>
  );
};

LoadingScreen.propTypes = {
  className: PropTypes.string,
};

export default memo(LoadingScreen);

import React from 'react';
import {Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from 'src/hooks/useAuth';
import {
  ROLE__ADMIN,
  ROLE__ADMIN__READ_ONLY,
  ROLE__SUPER_ADMIN,
} from 'src/utils/users/userAdminRoles';
import {ROUTE__ERROR__404} from 'src/constants/routes';

const AdminOrReadOnlyAdminGuard = ({children}) => {
  const {
    user: {role},
  } = useAuth();

  if (
    role !== ROLE__ADMIN &&
    role !== ROLE__ADMIN__READ_ONLY &&
    role !== ROLE__SUPER_ADMIN
  ) {
    return <Redirect to={ROUTE__ERROR__404} />;
  }

  return <>{children}</>;
};

AdminOrReadOnlyAdminGuard.propTypes = {
  children: PropTypes.node,
};

export default AdminOrReadOnlyAdminGuard;

// The following __variable__ is defined during the build process
/* eslint-disable-next-line no-undef */
const METRIC_PREFIX = `${__metricPrefix__}`;

const getMetricName = metric => metric.replace('saas.', `${METRIC_PREFIX}.`);

export const METRIC__STORAGE_SIZE_USED = getMetricName(
  'saas.all.storage.size.used.last.day',
);
export const METRIC__BUCKETS_SIZE_USED = getMetricName(
  'saas.all.buckets.all.size.last.day',
);
export const METRIC__FILES_TOTAL_COUNT = getMetricName(
  'saas.all.filetype.all.count.last.day',
);
export const METRIC__FILES_TOTAL_SIZE = getMetricName(
  'saas.all.filetype.all.size.last.day',
);

export const METRIC_TEMPLATE__FILETYPE_COUNT = getMetricName(
  'saas.all.filetype.<filetype>.count.last.day',
);
export const METRIC_TEMPLATE__FILETYPE_SIZE = getMetricName(
  'saas.all.filetype.<filetype>.size.last.day',
);
export const getFiletypeCountMetric = filetype =>
  METRIC_TEMPLATE__FILETYPE_COUNT.replace('<filetype>', filetype);
export const getFiletypeSizeMetric = filetype =>
  METRIC_TEMPLATE__FILETYPE_SIZE.replace('<filetype>', filetype);

export const METRIC_TEMPLATE__USERS_COUNT_PER_STORAGE_USAGE = getMetricName(
  'saas.all.account.storage.segmentation.<usageRange>.count.day',
);
export const getUsersCountPerStorageUsageMetric = usageRange =>
  METRIC_TEMPLATE__USERS_COUNT_PER_STORAGE_USAGE.replace(
    '<usageRange>',
    usageRange,
  );

export const METRIC__USER__AVG_DATA_SIZE = getMetricName(
  'saas.all.account.storage.used.average.day',
);
export const METRIC__USER__AVG_STORAGE_SIZE = getMetricName(
  'saas.all.buckets.all.used.average.day',
);

// Paying user metrics
export const METRIC__PAYING_USER__AVG_DATA_SIZE = getMetricName(
  'saas.all.storage.used.average_quota_gt_0.last.day',
);
export const METRIC__PAYING_USER__AVG_STORAGE_SIZE = getMetricName(
  'saas.all.buckets.all.used.average_quota_gt_0.day',
);

// module.exports is used because of i18next-scanner. It doesn't support imports.

const common = 'common';
const keyValue = 'keyValue';
const ssoClients = 'ssoClients';
const feedbacks = 'feedbacks';
const users = 'users';
const clientVersions = 'clientVersions';
const offers = 'offers';
const tenants = 'tenants';
const reports = 'reports';
const buckets = 'buckets';
const messages = 'messages';
const metrics = 'metrics';
const billing = 'billing';

const yupV8n = 'yupV8n';
const materialTable = 'materialTable';
const dateRangePicker = 'dateRangePicker';

const all = [
  common,
  yupV8n,
  dateRangePicker,
  materialTable,
  keyValue,
  ssoClients,
  feedbacks,
  users,
  clientVersions,
  offers,
  tenants,
  reports,
  buckets,
  messages,
  metrics,
  billing,
];

module.exports = {
  common,
  yupV8n,
  dateRangePicker,
  materialTable,
  keyValue,
  ssoClients,
  feedbacks,
  users,
  clientVersions,
  offers,
  tenants,
  reports,
  buckets,
  messages,
  metrics,
  billing,
  all,
};
